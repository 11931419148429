.inner-page.eam{
    .eam-logo{
        max-width: 100%;
        height: auto;    
    }
    h1{
        @include fluid-type($min_width, $max_width, 40px, 92px);
        color: #76da00;
        line-height: 0.75;
        margin-bottom: 50px;
    }    
    h2{
        color: #76da00;
        margin-bottom: 40px;
        @include fluid-type($min_width, $max_width, 40px, 92px);
    }
    .eam-border-text{
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #76da00;
    }
}
.eam-rounds-left, .eam-rounds-right{
    position: absolute;
    max-width: 20%;
    height: auto;
}
.eam-rounds-left{
    left: 0;
    bottom: 0;
}
.eam-rounds-right{
    right:0;
    bottom:100%; 
}
.eam-ver-left{
    @include media-breakpoint-up(md){
        transform: rotate(-90deg) scaleX(-1);
        position: absolute;
        top: -100%;  
        transform-origin: top left;
    }    
    font-family: $stalinist;
    left: 5%;
    margin-bottom: 40px;
    @include fluid-type($min_width, $max_width, 18px, 30px);
    span{
        @include media-breakpoint-up(md){
            transform: scaleX(-1);
        }
        display: block;
    }
}
.eam-ver-right{
    @include media-breakpoint-up(md){
        transform: rotate(-90deg) scaleX(-1);
        position: absolute;
        top: 100%;
        transform-origin: top right;
    }    
    font-family: $stalinist;
    right:5%;
    margin-bottom: 40px;
    @include fluid-type($min_width, $max_width, 18px, 30px);
    span{
        @include media-breakpoint-up(md){
            transform: scaleX(-1);
        }
        display: block;
    }
}