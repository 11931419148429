.inner-page.aam{
    h1{
        color: #ff9834;
        @include fluid-type($min_width, $max_width, 40px, 100px);
       span{
           display: block;
           -webkit-text-fill-color: transparent;
           -webkit-text-stroke-width: 0.5px;
           -webkit-text-stroke-color: #ff9834;           
            @include fluid-type($min_width, $max_width, 63px, 250px);
       }
    }
    h3, h2{
        color:#ff9834; ;
    }
    h2{
        @include fluid-type($min_width, $max_width, 40px, 92px);
    }
}
.amm-plus{
    margin-bottom: 150px;
    @include media-breakpoint-down(sm){
        float: left;
        position: absolute;
    }
}
.amm-dolors{
    position: relative;
    width: 100%;
    display: block;
    svg, img{
        max-width: 100%;
        height: auto; 
    }
    .amm-dolors-h{
        position: relative;  
        visibility: hidden;
        @include opacity(0);
    }
    .amm-dolors1{
        position: absolute;
        top: 2%;
        left: 0;
        width: 62%;
    }
    .amm-dolors2{
        position: absolute;
        top: 0%;
        left: 15%;
        width: 46%;
    }
    .amm-dolors3{
        position: absolute;
        top: 23%;
        right: 0;
        width: 46%;
    }
}
.aam-h2{
    display: flex;
    @include media-breakpoint-up(xl){
      width: max-content;
    }
    margin: 0px auto 20px;
    @include media-breakpoint-down(md){
        display: block;   
        span{
            display: block;
        }
    }
    small{
        display: block;       
    }
    .aam-rotate-h2{
        transform: rotate(-4deg);
        margin-right: 12px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #ff9834;
    }
}
.aam-border-text{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #ff9834;
}
.aam-prespect{
    span{
        display: block;
        transform: rotate(-4deg);
        transform-origin: left bottom;
        &:nth-child(2){
            transform: rotate(4deg);
        }
    }
}
.amm-warning{
    position: relative;
    margin:60px 0 90px;
    display: block;
    @include media-breakpoint-down(md){
        margin:60px 0 90px 50px;
    }
    .amm-warning1{
        position: relative;
        z-index: 9;
        max-width: 100%; 
        display: block;
        height: auto;
        right: 0;
    }
    .amm-warning2{
        z-index: 0;
    }
    .amm-warning3{
        z-index: -1;
    }
    .amm-warning4{
        z-index: -2;
    }
    .amm-warning5{
        z-index:-4;
    }
    .svg:not(.amm-warning1){
        position: absolute;
        top:0;
        left: 0;
        max-width: 100%;
        height: auto;
        min-width: 100px;
        min-height: 150px;
    }
}
.amm-ver-text{
    @include media-breakpoint-up(md){
        transform: rotate(-90deg) scaleX(-1);
        position: absolute;
        top: 0;
        transform-origin: top left;
    }    
    font-family: $stalinist;
    left: 25px;
    margin-bottom: 40px;
    @include fluid-type($min_width, $max_width, 18px, 30px);
    span{
        @include media-breakpoint-up(md){
            transform: scaleX(-1);
        }
        display: block;
    }
}