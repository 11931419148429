.post-list-block {
	background:$white-color;
	@include border-radius(15px);
	color:$black-color;
	overflow:hidden;
	margin-bottom:30px;
	position:relative;
	height:calc(100% - 30px);
	.entry-content{
		font-size:17px;
	}
	.post-list-text {
		padding:0px 15px;
		a {
			color:$black-color;
		}
		.post-meta {
			li.meta-wrapper {
				font-size:16px;
			a{
				display:inline-flex;
				align-items: center;
				letter-spacing: 1px;
			}		
			}
		}
	}
	strong {
		font-family:$montserrat;
		letter-spacing:0px;
		margin-bottom:15px;
		display:block;
		font-size:15px;
	}
	.featured-media-inner {
		img {
			height:auto;
		}
	}
}
.blog {
	.pagination-wrapper {
		.screen-reader-text {
			display:none;
		}
	}
	.nav-links {
		margin:0px auto;
		.page-numbers {
			border:2px solid $white-color;
			border-radius:2px;
			color:$white-color;
			margin:0px 3px;
			padding:0px 10px;
			float:left;
			&.placeholder {
				opacity:0.8;
			}
			&.current {
				background-color:$white-color;
				color:$black-color;
			}
		}
	}
}
.single-post{
	.inner-page {
		h1 {
			@include fluid-type($min_width, $max_width, 34px, 52px);
			line-height:1.2;
			font-weight:800;
			margin-bottom:60px;
		}
		h2{
			@include fluid-type($min_width, $max_width, 30px, 50px);
			font-weight: 600;
			line-height: 1;
			margin-bottom: 20px;
		}
		h3{
			@include fluid-type($min_width, $max_width, 28px, 46px);
			font-weight: 600;
			line-height: 1;
			margin-bottom: 20px;
		}
		.featured-media-inner {
			background-color:#fff;
			margin-bottom:74px;
			img {
				display:block;
				max-width:100%;
				width:100%;
				height:auto;
			}
		}
		#textSpeech{
			p{
				text-align: justify;
			}
		}
	}	
}
.author-linkedin-icon{
	width:25px;
  margin-right:7px;
  vertical-align:-5px;
}

.sound-buttons{	
	background: #090909;
	margin:0px auto;
	display:table;
	border: 1px solid $white-color;
	@include border-radius(10px);
	padding:0px 10px;
	text-align:center;
	.sound-button {
    height:32px;
    width:32px;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:100% 100%;
    display:inline-block;
		margin:7px 5px 0px 5px;
		cursor: pointer; 
		color:$black-color;
		user-select: none;
	}
	.form-control{
		background-color:transparent;
		border:none;
		outline:none;
		@include border-radius(0px);		
    padding: 0px;
		margin:0px 10px 0px 0px;
		//border-right:1px solid #424242;
    height: 44px;
    width: 160px;
		font-size: 14px;
    display: inline-block;
    vertical-align: top;
		display: table;
    margin: 0px auto;
		border-bottom:1px solid #2f2f2f;
		@include media-breakpoint-down(md){
		  //border-right:none;
			//border-bottom:1px solid #2f2f2f;
    	margin-bottom:3px;
			//height:48px;
			width: inherit;
			display: inherit;
		}
		&:focus, &:active{
			outline:none;
			box-shadow:none;
		}
		option{
			background-color:#020202;
		}
	}
	#play {
		background-image:url(../../img/play.svg);
		&:hover, &.active{
			background-image:url(../../img/play1.svg);
		}
	}
	#pause {
		background-image:url(../../img/pause.svg);
		&:hover, &.active{
			background-image:url(../../img/pause1.svg);
		}
	}
	#resume {
		background-image:url(../../img/resume.svg);
		&:hover, &.active{
			background-image:url(../../img/resume.png);
		}
	}
	#stop {
		background-image:url(../../img/stop.svg);
		&:hover, &.active{
			background-image:url(../../img/stop1.svg);
		}
	}
}

body.blog{
	background:$black-color url(../../img/noise-background.jpg) repeat;
	color:$white-color;
	header .container .navbar .navbar-nav>.menu-item>.nav-link{
		color:$white-color;
		@include media-breakpoint-down(md){
			color:$black-color;
		}
	}
	.navbar-brand{filter: invert(0);}
	.inner-page h1, .inner-page h2{
		color:$white-color;
	}
	footer ul li a{
		color:$white-color;
	}
	.btn{
		color:$white-color;
	}
	.contact-sec .contact-block p{
		color:$white-color;
	}
	.contact-sec .contact-block a{
		border: 1px solid $white-color;
		color:$white-color;
	}
	.contact-sec .contact-block button[type=submit]{
		filter: invert(0);
	}
	.hpost-titles .hpost-title{
		background-color:$white-color;
		a{
			color:$black-color;
		}
	}
	.quick-contact-btn{
		filter:invert(1);
	}
	.bs-tooltip-right .arrow::before{
		border-right-color:$white-color;
	}
	.tooltip .tooltip-inner{
		background-color:$white-color;
		color:$black-color;
	}
	@include media-breakpoint-down(md){
		header{
			filter:invert(0);
		}
	}
}