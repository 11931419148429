.wmapp.inner-page{
    h1{
        @include fluid-type($min_width, $max_width, 44px, 105px);
    }
}
.wmapp-middle-shadow{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    @include media-breakpoint-down(md){
        max-width: 100%; 
        transform: translate(-50%, -68%); 
    }
}
.wmapp-top-left-gr{
    position: absolute;
    left: 0;
    top: -40%; 
}
.wmapp-top-plus{
    position: absolute;
    left: 0px;
    top: -30px;
}
.wmapp-light-green-shape{
    height: 50px;
    width: 50px;
    position: absolute;
    z-index: -1;
    background-color: #85ff90;
    bottom: 100%;
    right: 50px;
    @include border-radius(8px);
}
.wmapp-pink-shape{
    height: 50px;
    width: 50px;
    position: absolute;
    z-index: -1;
    background-color: #ff005a;
    top: 0;
    right: -150px;
}