.ionic{
    position: relative;
    &:after{
        content: '';
        background: url(../../img/ionic-right-shadow.png) no-repeat right center;
        position: absolute;
        right: 0;
        top: 0;
        width: 290px;
        height: 100%;
        background-size: contain;
        z-index: -2;
    }
}
.ionic-red-plus-block{
    position: relative;
    .ionic-red-plus{
        position: absolute;
        right: 0;
        z-index: -1;
        max-width: 32%;
        height: auto;
        @include media-breakpoint-down(sm){
            @include opacity(0.5);
        }
    }
}
.ionic-shadow-tl {
    position: absolute;
    top: -30%;
    transform: translate(0%, -50%);
    left: 0;
    z-index: -1;
}

.ionic-plus-shape{
    position: absolute;
    right: -20px;
    top:50%;
    z-index: -1;
    @include media-breakpoint-down(sm){
      @include opacity(0.5);
    }
}
.ionic-aquamarine{
    width: 80px;
    height: 120px;
    background-color: #85ff90;
    position: absolute;
    right: -100px;
    top: 0px;
}
.ionic-left-gr{
    position: absolute;
    left: 0;
    top: -30%;
    z-index: -1;
    @include media-breakpoint-down(sm){
        top: -22%;
        max-width: 70%;
    }
}
            