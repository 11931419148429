.php-top-gr-block{
    width: 205px;
    height: 27px;
    @extend .php-gr; 
    margin-bottom: 10px;
}
.php-grblocks{
    display: table;
    margin: 0px auto;
    div{
        width: 100px;
        height: 215px;
        display: inline-block;
        margin: 0px 10px; 
        background: #02fff6;
        background: -moz-linear-gradient(top,  #02fff6 0%, #ffff00 25%, #ff008a 50%, #ffff00 75%, #02fff6 100%);
        background: -webkit-linear-gradient(top,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
        background: linear-gradient(to bottom,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02fff6', endColorstr='#02fff6',GradientType=0 );
        animation-name: grtopbottom;
        background-size:auto 200%;       
    }
}
.php{
    position: relative;
    section{
        @include media-breakpoint-up(xl){
            padding: 70px 0px; 
        }              
    }
    h1, h2{
        margin-bottom: 24px;
    }    
    .php-right-shadow{
        bottom: 0;
        top:auto;
    }
}
.php-round{
    max-width: 60%;
    height: auto;
}
.php-code{
    .code-gr{
        height: 100%;
        width: 7px;
        margin-right: 30px;
        @extend .php-gr-ver;
    }
    code{
        font-family: $stalinist;
        color: $white-color;
        font-size: 17px; 
        padding: 15px 0px;
        .yellow-code{
            color: #ffb100;
        }
        .blue-code{
            color: #7386e7;
        }
        .green-code{
            color: #73ff73;
        }
    }   
}
.php-gr{
    background: #02fff6;
    background: -moz-linear-gradient(left,  #02fff6 0%, #ffff00 25%, #ff008a 50%, #ffff00 75%, #02fff6 100%);
    background: -webkit-linear-gradient(left,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
    background: linear-gradient(to right,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02fff6', endColorstr='#02fff6',GradientType=1 );
    animation-name: stretch;
    background-size: 200% auto;
    animation-duration: 3s; 
    animation-timing-function: linear; 
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}
.php-gr-ver{
    background: #02fff6;
    background: -moz-linear-gradient(top,  #02fff6 0%, #ffff00 25%, #ff008a 50%, #ffff00 75%, #02fff6 100%);
    background: -webkit-linear-gradient(top,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
    background: linear-gradient(to bottom,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02fff6', endColorstr='#02fff6',GradientType=0 );
    animation-name: grtopbottom;
    background-size:auto 200%;
    animation-duration: 3s; 
    animation-timing-function: linear; 
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;    
}