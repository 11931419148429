
.pink-bg{
    background-color: #ff005a;
    padding: 50px 30px;
    border-radius: 16px;
    box-shadow: 38px 0px 152px rgba(255, 0, 90, 0.45);
    @include media-breakpoint-down(md){
        margin-bottom: 55px !important;
    }
}
.hybd-ele1{
    position: relative;  
    .hybrid-shadow-tl {
        position: absolute;
        top: -30%;
        transform: translate(0%, -50%);
        left: 0;
    }
    .hybrid-shape-tr{
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: 10%;
        @include media-breakpoint-down(md){
            right: 0; 
            max-width: 45%;
            height: auto;
        }
    }
}
.hbdr-blue{
    position: relative;
    .hbdr-blue-block{
        width: 80px;
        height: 120px;
        background-color: #85ff90;
        position: absolute;
        left: 0;
        top: 100%;
    }
}
.hybrid-shape-rb{    
    position: absolute;
    top: 5%;
    left: 30%;
    z-index: -1;
}
.hybd-ele2{
    position: relative;    
    .hybrid-round{  
        position: absolute;
        right: -30%;
        bottom: -50%;   
        @include media-breakpoint-down(md){
           position: relative;
           right: auto;
           left: 0;
           top: 0;
           bottom: auto;   
        }   
        .hybd-rnd1{
            position: absolute;
            @include media-breakpoint-down(md){
                max-width: 100%;
                height: auto;
            }
        }
        .hybd-rnd2{            
            animation-name: orbit;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-duration: 2.89016s;    
            transform-origin: 45% 45%;  
            @include media-breakpoint-down(md){
                max-width: 100%;
                height: auto;
            }  
        }
    }      
}