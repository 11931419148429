/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * halyard-display:
 *   - http://typekit.com/eulas/00000000000000003b9b224d
 *   - http://typekit.com/eulas/00000000000000003b9b224e
 *   - http://typekit.com/eulas/00000000000000003b9b2252
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-02-28 06:52:04 UTC"}*/
//@import url("https://p.typekit.net/p.css?s=1&k=ytz3sxu&ht=tk&f=34664.34666.34669&a=37057434&app=typekit&e=css");
@font-face {
font-family:"halyard-display";
src:url("https://use.typekit.net/af/25d0fd/00000000000000003b9b224d/27/l?subset_id=2&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/25d0fd/00000000000000003b9b224d/27/d?subset_id=2&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/25d0fd/00000000000000003b9b224d/27/a?subset_id=2&fvd=n4&v=3") format("opentype");
font-display: swap;font-style:normal;font-weight:400;
}
@font-face {
font-family:"halyard-display";
src:url("https://use.typekit.net/af/37c11c/00000000000000003b9b224e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/37c11c/00000000000000003b9b224e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/37c11c/00000000000000003b9b224e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display: swap;font-style:normal;font-weight:500;
}
@font-face {
font-family:"halyard-display";
src:url("https://use.typekit.net/af/107bb0/00000000000000003b9b2252/27/l?subset_id=2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/107bb0/00000000000000003b9b2252/27/d?subset_id=2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/107bb0/00000000000000003b9b2252/27/a?subset_id=2&fvd=n7&v=3") format("opentype");
font-display: swap;font-style:normal;font-weight:700;
}
.tk-halyard-display { font-family: "halyard-display",sans-serif; }