.lead-h2{
    span{
        position: relative;
        display: inline-block;
        color: $black-color;
        padding: 0px 7px;
        &:after{
            content:'';
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transform: rotate(-4deg);
            z-index: -1;
        }
    }
}
.lead-hands{
    display: flex;
    margin: 0px auto;
    max-width: 60%;
    justify-content: space-between;
    .lead-hands-left, .lead-hands-right{
        position: absolute;
        z-index: -1;
        max-width: 40%;
        display: inline-block;
        height: auto;
        @include media-breakpoint-down(md){
            position: static;
        }
    }
    .lead-hands-left{
        left: 14%;
        top: 0;
    }
    .lead-hands-right{
        right: 14%;
        top: 50%;
    }
}
