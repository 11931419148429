body.studio header{
  position: relative;
  z-index: 99;
}
 /* --- Overlays --- */
 .ashade-categories-overlay, .ashade-aside-overlay, .ashade-menu-overlay, .ashade-home-block-overlay{position:fixed;top:0;left:0;width:100%;height:100%;z-index:177;background:rgba(0,0,0,0.85);pointer-events:none;opacity:0;transition:opacity 0.6s;}
 .ashade-home-block-overlay{z-index:16;}
 .ashade-aside-overlay{z-index:222;}
 .ashade-home-block-overlay{background:rgba(0,0,0,0.75);}
 .ashade-aside-shown .ashade-aside-overlay, .ashade-menu-fade .ashade-menu-overlay, .is-faded .ashade-home-block-overlay{opacity:1;}
 /* --- Grid --- */
 .ashade-row{display:flex;flex-direction:row;margin:0 -20px;}
 .ashade-row.is-reverse{flex-direction:row-reverse;}
 .ashade-row.ashade-no-gap{margin:0;}
 .ashade-row.ashade-small-gap{margin:0 -10px;}
 .ashade-row-middle{align-items:center;}
 .ashade-row-fullheight{min-height:100vh;align-items:stretch;}
 .ashade-col{margin:0 20px;}
 .ashade-col.align-top{display:inline-flex;flex-direction:column;justify-content:flex-start;}
 .ashade-col.align-middle{display:inline-flex;flex-direction:column;justify-content:center;}
 .ashade-col.align-bottom{display:inline-flex;flex-direction:column;justify-content:flex-end;}
 .ashade-row.ashade-no-gap .ashade-col{margin:0;}
 .ashade-row.ashade-small-gap .ashade-col{margin:0 10px;}
 .ashade-col.col-12{width:calc(100% - 40px);}
 .ashade-col.col-9{width:calc(75% - 40px);}
 .ashade-col.col-8{width:calc(66.66% - 40px);}
 .ashade-col.col-6{width:calc(50% - 40px);}
 .ashade-col.col-4{width:calc(33.33% - 40px);}
 .ashade-col.col-3{width:calc(25% - 40px);}
 .ashade-col.col-2{width:calc(16.665% - 40px);}
 .ashade-row.ashade-no-gap .ashade-col.col-12{width:100%;}
 .ashade-row.ashade-no-gap .ashade-col.col-9{width:75%;}
 .ashade-row.ashade-no-gap .ashade-col.col-8{width:66.66%;}
 .ashade-row.ashade-no-gap .ashade-col.col-6{width:50%;}
 .ashade-row.ashade-no-gap .ashade-col.col-4{width:33.33%;}
 .ashade-row.ashade-no-gap .ashade-col.col-3{width:25%;}
 .ashade-row.ashade-no-gap .ashade-col.col-2{width:16.665%;}
 .ashade-row.ashade-small-gap .ashade-col.col-12{width:calc(100% - 20px);}
 .ashade-row.ashade-small-gap .ashade-col.col-9{width:calc(75% - 20px);}
 .ashade-row.ashade-small-gap .ashade-col.col-8{width:calc(66.66% - 20px);}
 .ashade-row.ashade-small-gap .ashade-col.col-6{width:calc(50% - 20px);}
 .ashade-row.ashade-small-gap .ashade-col.col-4{width:calc(33.33% - 20px);}
 .ashade-row.ashade-small-gap .ashade-col.col-3{width:calc(25% - 20px);}
 .ashade-row.ashade-small-gap .ashade-col.col-2{width:calc(16.665% - 20px);}
 .ashade-contact-row{display:flex;flex-direction:row;margin:0 -10px;}
 .ashade-contact-col{margin:0 10px;width:calc(50% - 20px);}
 .ashade-contact-col.col-4{margin:0 10px;width:calc(33.33% - 20px);}
 /* --- Section --- */
 #ashade-home-works p.ashade-intro, section.ashade-section{display:block;width:100%;}
 #ashade-home-contacts p.ashade-intro{margin-bottom:80px;}
 
 /* --- Disable Selection --- */
 a img, .ashade-grid-item img, .ashade-tns-container, .ashade-albums-carousel-wrap, header#ashade-header a, .ashade-mobile-title-wrap, .ashade-page-title-wrap, .ashade-back-wrap, .ashade-select-disabled{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
 /* --- Ashade Cursor --- */
 h1.ashade-page-title{padding-top: 20px;}
 
 /* 05. CONTENT ----------- */
 .ashade-content-wrap{position:relative;z-index:5;display:flex;flex-direction:column;justify-content:center;transition:transform 0.5s;overflow-x:hidden;min-height:100vh;}
 .admin-bar .ashade-content-wrap{min-height:calc(100vh - 32px);}
 .ashade-smooth-scroll .ashade-content-wrap{/*position: fixed;*/width:100%;left:0;top:0;z-index:5;display:block;overflow:hidden;}
 .ashade-content{margin:auto;min-height:100%;padding-top:115px;padding-bottom:2px;}
 .no-top-padding .ashade-content{padding-top:0;}
 .no-bottom-padding .ashade-content{padding-bottom:0;}
 .ashade-smooth-scroll .ashade-content-scroll.is-centered{min-height:100vh;display:flex;flex-direction:column;justify-content:center;}
 .ashade-content-scroll.is-centered .ashade-content{display:flex;flex-direction:column;justify-content:center;}
 /* --- Page Title --- */
 body.ashade-layout--vertical .ashade-page-title-wrap{position:fixed;top:0;width:100vh;display:flex;justify-content:center;transform-origin:0% 0%;transform:rotate(-90deg);transition:transform 0.5s;z-index:77;cursor:default;pointer-events:none;}
 body.ashade-layout--horizontal .ashade-page-title-wrap{padding:0 50px 0 50px;position:relative;transition:none;}
 body.ashade-layout--horizontal .ashade-page-title-wrap h1{margin:45px 0 0 0;}
 body.ashade-layout--horizontal.single-attachment .ashade-page-title-wrap h1{margin:0;}
 body.ashade-layout--horizontal .ashade-page-title-wrap:not(.ashade-page-title--is-alone){transform:translateX(-100%);}
 body.ashade-layout--horizontal.no-top-padding .ashade-page-title-wrap{margin:0 0 45px 0;}
 .ashade-albums-slider-wrap .ashade-album-item__title, body.ashade-layout--vertical .ashade-page-title-wrap{left:136px;}
 .ashade-mobile-title-wrap:before, .ashade-page-title-wrap:before{content:'';width:100%;height:1px;background:rgba(255,255,255,0.15);position:absolute;left:0;top:23px;}
 body.ashade-layout--horizontal .ashade-page-title-wrap:before{top:20px;}
 body.ashade-layout--horizontal .ashade-protected-inner .ashade-page-title-wrap:before{top:66px;}
 body.ashade-layout--horizontal .ashade-page-title-wrap h1{word-wrap:break-word;}
 
 body.ashade-layout--horizontal .ashade-page-title-wrap h1 > span{transform:translateX(-150px);}
 body.ashade-layout--horizontal .ashade-page-title-wrap.is-loaded h1 > span{transform:translateX(-50px);}
 .ashade-mobile-title-wrap h1 a, .ashade-page-title-wrap h1 a{pointer-events:all;}
 .ashade-mobile-title-wrap h1 > span > span, .ashade-page-title-wrap h1 > span > span{display:inline-block;vertical-align:top;margin-left:40px;position:relative;}
 .ashade-mobile-title-wrap h1 > span > span.ashade-meta-author, .ashade-page-title-wrap h1 > span > span.ashade-meta-author, .ashade-mobile-title-wrap h1 > span > span.ashade-meta-date, .ashade-page-title-wrap h1 > span > span.ashade-meta-date{white-space:nowrap;}
 .ashade-mobile-title-wrap h1 > span > span:before, .ashade-page-title-wrap h1 > span > span:before{content:'';width:20px;height:1px;position:absolute;left:-30px;top:8px;background:rgba(255,255,255,0.15);}
 .ashade-mobile-title-wrap h1 > span > span:first-child:before, .ashade-page-title-wrap h1 > span > span:first-child:before{display:none;}
 .ashade-mobile-title-wrap h1 > span > span:first-child, .ashade-page-title-wrap h1 > span > span:first-child{margin-left:0;}
 .ashade-mobile-title-wrap h1, .ashade-page-title-wrap.is-loaded h1{opacity:1;padding-left:50px;}
 
 .ashade-albums-template--slider .ashade-page-title-wrap a, .ashade-albums-template--slider .ashade-page-title-wrap.is-loaded h1 span{color:rgba(255,255,255,0.3);}
 .single-ashade-clients .ashade-page-title-wrap.is-loaded h1 span{color:rgba(255,255,255,0.3);}
 .ashade-albums-template--slider .ashade-page-title-wrap a:hover{color:rgba(255,255,255,1);}
 .ashade-mobile-title-wrap{position:relative;width:100%;text-align:center;}
 .ashade-mobile-title-wrap h1{padding:20px 0px 0px 0px;text-align:center;}
 .ashade-mobile-title-wrap:before{width:200vw;left:50%;top:0px;transform:translateX(-50%);}
 body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone:before{display:none;}
 body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone h1{display:inline-block;position:relative;padding-left:50px;}
 body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone h1:before, body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone h1:after{content:'';height:1px;background:rgba(255,255,255,0.15);position:absolute;left:0;top:25px;transition:transform 0.5s;}
 body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone h1:before{width:50px;left:-50px;transform:translateX(-50px);}
 body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone h1:after{left:calc(100% + 50px);width:100vw;transform:translateX(50px);}
 body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone.is-loaded h1:before, body.ashade-layout--horizontal .ashade-page-title-wrap.ashade-page-title--is-alone.is-loaded h1:after{transform:translateX(0);}
 .ashade-mobile-title-wrap h1 > span{margin-bottom:7px;}
 .ashade-albums-template--slider.ashade-layout--horizontal .ashade-page-title-wrap h1{margin-top:0;}
 .ashade-albums-template--slider.ashade-layout--horizontal .ashade-page-title-wrap{position:fixed;bottom:120px;left:0;width:100%;z-index:5;pointer-events:none;}
 /* --- Back Button --- */
 body.ashade-layout--vertical .ashade-back-wrap{position:fixed;top:200%;left:calc(100vw - 182px);width:100vh;display:flex;justify-content:flex-start;transform-origin:0% 0%;transform:rotate(-90deg) translate(0, 0);z-index:99;cursor:default;text-align:right;padding-left:100px;}
 body.single-ashade-albums.ashade-albums-back.no-history .ashade-back-wrap.ashade-ribbon-return, body.single-ashade-albums.ashade-albums-back.no-history .ashade-back-wrap.ashade-slider-return, body.single-ashade-albums.ashade-albums-back.no-history .ashade-back.albums-go-back{display:none!important;}
 body.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-ribbon-return, body.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-slider-return{pointer-events:none;}
 body.ashade-layout--vertical.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-ribbon-return, body.ashade-layout--vertical.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-slider-return, body.ashade-layout--horizontal.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap{flex-direction:row-reverse;justify-content:space-between;}
 body.ashade-layout--horizontal.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-ribbon-return{position:fixed;bottom:85px;left:0;z-index:55;}
 body.ashade-layout--horizontal.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-slider-return > div, body.ashade-layout--horizontal.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-ribbon-return > div{margin:0;}
 body.ashade-layout--horizontal.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-slider-return:before{display:none;}
 body.ashade-layout--horizontal.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap.ashade-slider-return{position:fixed;bottom:130px;left:0;z-index:55;}
 body.ashade-layout--vertical.single-ashade-albums.ashade-albums-back.has-history .ashade-back-wrap{justify-content:space-between;padding-right:150px;}
 .has-admin-bar body.ashade-layout--vertical.single-ashade-albums.ashade-albums-back .ashade-back-wrap{padding-right:182px;}
 body.ashade-layout--vertical .ashade-back-wrap:before{content:'';width:100%;height:1px;background:rgba(255,255,255,0.15);position:absolute;left:0;top:21px;}
 .ashade-back-wrap .ashade-back{padding-right:25px;cursor:pointer;pointer-events:none;}
 .ashade-back span{display:block;transition:transform 0.5s, color 0.5s;}
 body.ashade-layout--horizontal .ashade-back-wrap{text-align:right;width:100%;display:flex;justify-content:flex-end;position:relative;pointer-events:none;padding:0 75px 0 50px;transform:translateX(100%);}
 body.ashade-layout--horizontal .ashade-back-wrap > div{margin:0 0 45px 0;}
 body.ashade-layout--horizontal.no-bottom-padding .ashade-back-wrap{margin:45px 0 0 0;}
 body.ashade-layout--horizontal .ashade-back-wrap .ashade-back{pointer-events:all;padding-right:0;}
 body.ashade-layout--horizontal .ashade-back-wrap:before{content:'';position:absolute;height:1px;background:rgba(255,255,255,0.15);top:19px;width:100vw;left:0;}
 /* Before Load State */
 .ashade-back-wrap .ashade-back span:first-child{margin:0 0 3px 0;transform:translateX(65px);color:rgba(92,92,96,0);}
 .ashade-back-wrap .ashade-back span:last-child{margin:0;color:rgba(255,255,255,0);transform:translateX(-40px);}
 .ashade-back-wrap.is-loaded .ashade-back:not(.is-to-top), .has-to-top .ashade-back-wrap.is-loaded .ashade-back.is-to-top{pointer-events:auto;}
 .ashade-back-wrap.is-loaded .ashade-back.is-to-top:hover.in-action, .ashade-back-wrap.is-loaded .ashade-back.is-to-top.in-action{pointer-events:none;}
 /* Normal State */
 .ashade-back-wrap.is-loaded .ashade-back:not(.is-to-top) span:first-child, .has-to-top .ashade-back-wrap.is-loaded .ashade-back.is-to-top span:first-child{margin:0 0 3px 0;color:rgba(92,92,96,1);transform:translateX(25px);}
 .ashade-back-wrap.is-loaded .ashade-back:not(.is-to-top) span:last-child, .has-to-top .ashade-back-wrap.is-loaded .ashade-back.is-to-top span:last-child{margin:0;transform:translateX(0px);}
 /* Hover State */
 .ashade-back-wrap.is-loaded .ashade-back.is-to-top:hover span:first-child, .ashade-back-wrap.is-loaded .ashade-back:hover span:first-child{transform:translateX(15px);}
 .ashade-back-wrap.is-loaded .ashade-back.is-to-top:hover span:last-child, .ashade-back-wrap.is-loaded .ashade-back:hover span:last-child{transform:translateX(10px);color:#fff;}
 .ashade-back-wrap .ashade-back.in-action, .has-to-top .ashade-back-wrap .ashade-back.in-action{pointer-events:none;}
 /* Remove to Inactive State */
 body:not(.has-to-top) .ashade-back-wrap .ashade-back.is-to-top{pointer-events:none;}
 body:not(.has-to-top) .ashade-back-wrap .ashade-back.is-to-top span:first-child, .ashade-back-wrap .ashade-back.in-action.is-to-top span:first-child, .ashade-back-wrap .ashade-back.in-action span:first-child, .has-to-top .ashade-back-wrap .ashade-back.in-action.is-to-top span:first-child, .has-to-top .ashade-back-wrap .ashade-back.in-action span:first-child{margin:0 0 3px 0;transform:translateX(-35px);color:rgba(92,92,96,0);}
 body:not(.has-to-top) .ashade-back-wrap .ashade-back.is-to-top span:last-child, .ashade-back-wrap .ashade-back.in-action.is-to-top span:last-child, .ashade-back-wrap .ashade-back.in-action span:last-child, .has-to-top .ashade-back-wrap .ashade-back.in-action.is-to-top span:last-child, .has-to-top .ashade-back-wrap .ashade-back.in-action span:last-child{margin:0;color:rgba(255,255,255,0);transform:translateX(60px);}
 body.ashade-layout--vertical .ashade-to-top-wrap.ashade-mobile-b2t{position:relative;transform:none;left:auto;top:auto;width:100%;padding:0;justify-content:center;display:none;}
 
 /* 09. ALBUMS LISTING ------------------ */
 .ashade-grid{margin:-20px;display:flex;flex-wrap:wrap;}
 .ashade-grid.ashade-small-gap{margin:-10px;}
 .ashade-grid.ashade-no-gap{margin:0;}
 .ashade-grid .ashade-grid-item{margin:20px;backface-visibility:visible;transition:opacity 0.5s;overflow:visible;}
 .ashade-grid:not(.is-masonry) .ashade-grid-item{transition:opacity 0.5s, transform 0.5s;}
 .ashade-grid .ashade-grid-item > .ashade-grid-item--inner{transition:transform 0.5s;backface-visibility:visible;overflow:hidden;position:relative;}
 .ashade-gallery-bricks .ashade-grid-item{transition:opacity 0.5s, transform 0.5s;}
 .ashade-gallery-bricks.ashade-grid--hovered .ashade-grid-item, .ashade-grid.ashade-grid--hovered .ashade-grid-item{opacity:0.7;}
 .ashade-gallery-bricks.ashade-grid--hovered .ashade-grid-item:hover, .ashade-grid.ashade-grid--hovered .ashade-grid-item:hover{opacity:1;}
 .ashade-gallery-bricks.ashade-grid--hovered .ashade-grid-item:hover{transform:scale(1.03);}
 .ashade-grid.ashade-grid-2cols .ashade-grid-item:hover .ashade-grid-item--inner, .ashade-grid.ashade-grid-2cols.ashade-grid--hovered .ashade-grid-item:hover .ashade-grid-item--inner{transform:scale(1.03);}
 .ashade-grid.ashade-grid-3cols .ashade-grid-item:hover .ashade-grid-item--inner, .ashade-grid.ashade-grid-3cols.ashade-grid--hovered .ashade-grid-item:hover .ashade-grid-item--inner{transform:scale(1.045);}
 .ashade-grid.ashade-grid-4cols .ashade-grid-item:hover .ashade-grid-item--inner, .ashade-grid.ashade-grid-4cols.ashade-grid--hovered .ashade-grid-item:hover .ashade-grid-item--inner{transform:scale(1.06);}
 .ashade-grid.ashade-small-gap .ashade-grid-item{margin:10px;}
 .ashade-grid.ashade-no-gap .ashade-grid-item{margin:0;}
 .ashade-grid-1cols .ashade-grid-item{width:calc(100% - 40px);}
 .ashade-grid-2cols .ashade-grid-item{width:calc(50% - 40px);}
 .ashade-grid-3cols .ashade-grid-item{width:calc(33.33% - 40px);}
 .ashade-grid-4cols .ashade-grid-item{width:calc(25% - 40px);}
 .ashade-grid-5cols .ashade-grid-item{width:calc(20% - 40px);}
 .ashade-small-gap.ashade-grid-1cols .ashade-grid-item{width:calc(100% - 20px);}
 .ashade-small-gap.ashade-grid-2cols .ashade-grid-item{width:calc(50% - 20px);}
 .ashade-small-gap.ashade-grid-3cols .ashade-grid-item{width:calc(33.33% - 20px);}
 .ashade-small-gap.ashade-grid-4cols .ashade-grid-item{width:calc(25% - 20px);}
 .ashade-small-gap.ashade-grid-5cols .ashade-grid-item{width:calc(20% - 20px);}
 .ashade-no-gap.ashade-grid-1cols .ashade-grid-item{width:100%;}
 .ashade-no-gap.ashade-grid-2cols .ashade-grid-item{width:50%;}
 .ashade-no-gap.ashade-grid-3cols .ashade-grid-item{width:33.33%;}
 .ashade-no-gap.ashade-grid-4cols .ashade-grid-item{width:25%;}
 .ashade-no-gap.ashade-grid-5cols .ashade-grid-item{width:20%;}
 /* --- Albums Filter --- */
 .ashade-filter-wrap{display:flex;justify-content:center;flex-wrap:wrap;margin:0 0 60px 0;}
 .ashade-filter-wrap a{margin:0 20px;white-space:nowrap;display:block;transition:color 0.3s;}
 .ashade-mobile-filter-wrap{position:relative;display:none;width:100%;}
 .ashade-filter-wrap.is-open{z-index:999;position:relative;}
 .ashade-mobile-filter{display:flex;align-items:stretch;height:50px;}
 .ashade-mobile-filter > span{display:flex;align-items:center;}
 .ashade-mobile-filter-label{white-space:nowrap;padding:0 20px;}
 .ashade-mobile-filter-value{white-space:nowrap;padding:0 20px;}
 .ashade-mobile-filter-wrap svg{position:absolute;right:19px;top:21px;pointer-events:none;transition:transform 0.3s;}
 .is-open .ashade-mobile-filter-wrap svg{transform:scaleY(-1) translateY(1px);}
 .ashade-mobile-filter-list{position:absolute;left:0;top:calc(100% - 2px);width:100%;max-height:70vh;overflow-y:auto;padding:0;margin:0;transition:border-color 0.3s;z-index:999;}
 .ashade-mobile-filter-list li{display:block;padding:10px 20px;margin:0;list-style:none;transition:color 0.3s;cursor:pointer;}
 /* --- Albums Filtered Grid --- */
 .ashade-grid.has-filter{position:relative;}
 .ashade-grid.has-filter.ashade-grid-filtered > .ashade-grid-item{position:absolute;left:0;top:0;}
 .ashade-grid-item-hidden{pointer-events:none;opacity:0;}
 .ashade-grid-item-hidden > .ashade-grid-item--inner{transform:scale(0);}
 /* --- Albums Grid --- */
 .ashade-album-item{position:relative;overflow:hidden;align-self: center;margin-bottom: 45px;}
 .ashade-album-item a.ashade-album-item__link{position:absolute;top:0;right:0;bottom:0;left:0;display:block;}
 .ashade-album-item .ashade-album-item__image{overflow:hidden;backface-visibility:hidden;}
 .ashade-album-item img, .ashade-album-item video{width:100%;height:auto;display:block;transition:transform 0.5s;backface-visibility:hidden;}
 .ashade-album-item:hover img,  .ashade-album-item:hover video{transform:scale(1.05);}
 .ashade-album-item h5{margin:0 0 -5px 0;padding:15px 0 0 0;}
  
 /* --- Grid Layout --- */
 .ashade-grid-caption--none .ashade-grid-caption{display:none;}
 .ashade-grid-caption--under .ashade-grid-caption{display:block;pointer-events:none;}
 .ashade-grid-caption--on_photo .ashade-grid-caption, .ashade-grid-caption--on_hover .ashade-grid-caption{position:absolute;bottom:0;left:0;width:100%;pointer-events:none;}
 .ashade-grid-caption--on_hover .ashade-grid-caption{opacity:0;transform:translateY(10px);transition:opacity 0.3s, transform 0.3s;}
 .ashade-grid-caption--on_hover .ashade-gallery-item:hover .ashade-grid-caption{opacity:1;transform:translateY(0);}
 .ashade-gallery-item{position:relative;}
 .ashade-gallery-item .ashade-grid-item--inner > a, .ashade-gallery-item > a{position:absolute;left:0;top:0;width:100%;height:100%;z-index:5;}
 .ashade-grid-caption{margin:15px 0 0 0;text-transform:uppercase;backface-visibility:visible;}
 .ashade-grid-caption--on_photo .ashade-grid-caption, .ashade-grid-caption--on_hover .ashade-grid-caption{background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,0.75) 100%);padding:15px 20px 10px 20px;}
 /* --- Bricks Layout --- */
 .ashade-gallery-bricks{display:flex;flex-direction:row;flex-wrap:wrap;margin:-20px;}
 .ashade-gallery-bricks .ashade-gallery-item{margin:20px;}
 .ashade-gallery-bricks .ashade-gallery-item img{display:block;width:100%;height:auto;}
 .ashade-gallery-bricks.is-1x2 .ashade-gallery-item{width:calc(50% - 40px);}
 .ashade-gallery-bricks.is-1x2 .ashade-gallery-item:nth-child(3n){width:calc(100% - 40px);}
 .ashade-gallery-bricks.is-2x3 .ashade-gallery-item.is-large{width:calc(50% - 40px);}
 .ashade-gallery-bricks.is-2x3 .ashade-gallery-item.is-small{width:calc(33.33% - 40px);}
 /* --- Adjusted --- */
 .ashade-gallery-adjusted .ashade-grid-item{display:inline-flex;flex-direction:column;justify-content:center;}
 /* 11. ELEMENTS ------------ */
 a.ashade-more-link{display:inline-block;text-transform:uppercase;}
 a.ashade-more-link:hover:after{margin:0 0 0 20px;}
 .ashade-page-background{position:fixed;left:0;top:0;width:100%;height:100%;background:no-repeat center;background-size:cover;transition:left 0.5s;}
 .ashade-aside-shown .ashade-page-background{left:-96px;}
 
 /* --- Contact Elements --- */
 .ashade-maintenance-form-wrap{max-width:960px;margin:0 auto;}
 p.ashade-intro{max-width:735px;text-align:center;display:block;margin:0 auto 50px auto;}
 p.ashade-intro.align-left{text-align:left;}
 p.ashade-intro.align-right{text-align:right;}
 
 
 /* --- Vertical Layout --- */
 .ashade-layout--vertical .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap{left:136px;}
 .ashade-layout--vertical .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap{justify-content:flex-end;text-align:left;padding-right:200px;}
 .ashade-layout--vertical .ashade-404-inner .ashade-back-wrap.ashade-404-home-wrap{justify-content:flex-start;padding-left:150px;}
 /* --- Horizontal Layout --- */
 .ashade-layout--horizontal .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap{padding-left:75px;transform:translateX(-100%);}
 .ashade-layout--horizontal .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap{justify-content:flex-start;text-align:left;}
 .ashade-layout--horizontal .ashade-404-inner .ashade-back-wrap.ashade-404-home-wrap{justify-content:flex-end;}
 .ashade-layout--horizontal .ashade-404-inner .ashade-back-wrap{position:relative;min-width:100%;}
 /* --- Horizontal Layout --- */
 .ashade-layout--horizontal .ashade-protected-inner .ashade-page-title-wrap{width:100%;}
 
 /* 18. DISABLE LOADING ANIMATIONS ------------------------------ */
 body.ashade-layout--vertical:not(.ashade-loading--full) .ashade-back-wrap:not(.is-inactive), body.ashade-layout--vertical:not(.ashade-loading--full) .ashade-page-title-wrap:not(.is-inactive){top:100%;}
 body.ashade-layout--horizontal:not(.ashade-loading--full) .ashade-page-title-wrap:not(.is-inactive), body.ashade-layout--horizontal:not(.ashade-loading--full) .ashade-back-wrap:not(.is-inactive){transform:translateX(0);}
 body:not(.ashade-loading--full) .ashade-home-link--contacts:not(.is-inactive), body:not(.ashade-loading--full) .ashade-home-link--works:not(.is-inactive){top:100%;}
 body:not(.ashade-loading--full) .ashade-nav ul.main-menu > li, body:not(.ashade-loading--full) .ashade-footer__socials li, body:not(.ashade-loading--full) .ashade-mobile-header > a, body:not(.ashade-loading--full) .ashade-footer__copyright, body:not(.ashade-loading--full) .ashade-logo{transform:translate(0);opacity:1;}
 body:not(.ashade-loading--full) .ashade-page-background{transform:scale(1);}
 body:not(.ashade-loading--full) .ashade-protected-text, body:not(.ashade-loading--full) .ashade-404-text, body:not(.ashade-loading--full) .ashade-content{opacity:1;transform:translate(0);}
 body:not(.ashade-loading--full) .ashade-albums-carousel .ashade-album-item__inner{opacity:1;transform:translate(0);}
 body:not(.ashade-loading--full) .ashade-album-item__explore, body:not(.ashade-loading--full) .ashade-album-item__title{top:100%;}
 body:not(.ashade-loading--full) .ashade-slider-prev, body:not(.ashade-loading--full) .ashade-slider-next{transform:translate(0);}
 body:not(.ashade-loading--full) .ashade-album-item__image{transform:scale(1);opacity:1;}
 body.ashade-loading--fade{opacity:0;}
 body.ashade-loading--fade.is-loaded{opacity:1;transition:opacity 1s;}
 body.ashade-loading--fade.is-loaded.is-locked{transition:none;}
 body.ashade-loading--fade.is-locked{opacity:1;}
 body.ashade-unloading--fade.is-fade-unload{opacity:1;transition:none;}
 
 //.page-template-studio footer, .page-template-studio .contact-sec{display:none;}
 .ashade-intro{font-family: $perfect-dos;}
.ashade-album-item .video{display: block;position:relative;}
.ashade-album-item .video:after{content:'';position:absolute;height:60px;width:60px;background:url(../../img/play-icon.png);background-size: cover;z-index:9;top: 50%;left: 50%;margin-left: -30px;margin-top: -30px;}
 /****************RESPONSIVE**************/
 /* Content Resizer */
@media only screen and (max-width:1800px){
.ashade-content{width:calc(100% - 352px);}
.ashade-more-categories{max-width:calc(100% - 352px);}
body.ashade-layout--vertical .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap, body.ashade-layout--vertical:not(.ashade-albums-template--slider) .ashade-page-title-wrap{left:70px;}
 body.ashade-layout--vertical .ashade-back-wrap{left:calc(100% - 126px);}
.ashade-page-title-wrap:before{top:21px;}
.ashade-layout--horizontal .ashade-protected-inner .ashade-page-title-wrap:before{top:65px;}
}
@media only screen and (max-width:1679px){
.ashade-layout--vertical .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap, body.ashade-layout--vertical:not(.ashade-albums-template--slider) .ashade-page-title-wrap{left:50px;}
 body.ashade-layout--vertical .ashade-back-wrap{left:calc(100% - 126px);}
}
@media only screen and (max-width:1600px){
.ashade-content{width:calc(100% - 312px);}
.ashade-more-categories{max-width:calc(100% - 312px);}
.ashade-layout--vertical .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap, body.ashade-layout--vertical:not(.ashade-albums-template--slider) .ashade-page-title-wrap{left:40px;}
 body.ashade-layout--vertical .ashade-back-wrap{left:calc(100% - 116px);}
}
@media only screen and (max-width:1200px){
.ashade-content{width:calc(100% - 272px);}
.ashade-more-categories{max-width:calc(100% - 272px);}
.ashade-layout--vertical .ashade-404-inner .ashade-back-wrap.ashade-404-return-wrap, body.ashade-layout--vertical:not(.ashade-albums-template--slider) .ashade-page-title-wrap{left:30px;}
 body.ashade-layout--vertical .ashade-back-wrap{left:calc(100% - 106px);}
}
@media only screen and (max-width:782px){
body.admin-bar header#ashade-header{top:46px;}
body.admin-bar.ashade-smooth-scroll .ashade-header-holder{margin-top:46px;}
.admin-bar .ashade-content-wrap{min-height:calc(100vh - 46px);}
html.has-admin-bar{min-height:calc(100% - 46px);}
.ashade-smooth-scroll .ashade-content-wrap{position: static;}
.page-template-studio{height: auto !important;}
.ashade-content-scroll {transform: none !important;}
.mfp-iframe-scaler{padding-top: 90%;}
}
@media only screen and (max-width:960px){
.ashade-albums-template--ribbon .ashade-albums-carousel-wrap .ashade-albums-carousel.is-vertical,.ashade-content{width:calc(100% - 176px);margin:auto 40px auto auto;}
body.ashade-layout--horizontal .ashade-content{margin:auto;}
.ashade-more-categories{max-width:calc(100% - 176px);}
body.ashade-home-template .ashade-content{width:calc(100% - 280px);margin:auto;}
}
/* Laptop Styles */
@media only screen and (max-width:1440px){
body .hide-on-laptop,body section.ashade-section.hide-on-laptop{display:none;}
.ashade-filter-wrap a{margin:0 15px;}
/* --- Grid --- */
body .elementor-container.elementor-column-gap-default .elementor-row{margin:0 -15px;width:calc(100% + 30px);}
body .elementor-container.elementor-column-gap-default .elementor-row .elementor-container.elementor-column-gap-default .elementor-row{width:calc(100% + 30px);}
body .elementor-column-gap-default > .elementor-row > .elementor-column > .elementor-element-populated{padding:0 15px;}
.ashade-row{display:flex;flex-direction:row;margin:0 -15px;}
.ashade-col{margin:0 15px;}
.ashade-col.col-12{width:calc(100% - 30px);}
.ashade-col.col-9{width:calc(75% - 30px);}
.ashade-col.col-8{width:calc(66.66% - 30px);}
.ashade-col.col-6{width:calc(50% - 30px);}
.ashade-col.col-4{width:calc(33.33% - 30px);}
.ashade-col.col-3{width:calc(25% - 30px);}
.ashade-col.col-2{width:calc(16.665% - 30px);}
section.ashade-section{display:block;width:100%;padding: 30px 0px;}
.ashade-content section.ashade-section:last-child{margin-bottom:0;}
/* --- Content General --- */
p.ashade-intro{margin-bottom:60px;}
.ashade-page-title-wrap.is-loaded h1{padding-left:30px;}
.ashade-page-title-wrap.is-loaded h1 > span{transform:translateX(-30px);}
/* --- Contacts Page --- */
.ashade-contact-details__list li{padding-left:48px;min-height:30px;}
.ashade-contact-icon{width:36px;height:36px;border-radius:18px;transform:translateY(-1px);}
.ashade-contact-icon:before{transform:translate(-1px,-1px);}
.asiade-icon--socials.ashade-contact-icon:before{transform:translate(-2px,-2px);}
.asiade-icon--phone.ashade-contact-icon:before{transform:translate(-2px,-1px);}
}
/* Tablet Landscape Styles */
@media only screen and (max-width:1200px){
body .hide-on-tablet,body .hide-on-tablet-land,body section.ashade-section.hide-on-tablet,body section.ashade-section.hide-on-tablet-land{display:none;}
body .hide-on-desktop,body .hide-on-laptop,body section.ashade-section.hide-on-desktop,body section.ashade-section.hide-on-laptop{display:block;}
/* --- Grid --- */
.ashade-row{display:flex;flex-direction:row;margin:0 -10px;}
.ashade-col{margin:0 10px;}
.ashade-col.col-12{width:calc(100% - 20px);}
.ashade-col.col-9{width:calc(75% - 20px);}
.ashade-col.col-8{width:calc(66.66% - 20px);}
.ashade-col.col-6{width:calc(50% - 20px);}
.ashade-col.col-4{width:calc(33.33% - 20px);}
.ashade-col.col-3{width:calc(25% - 20px);}
.ashade-col.col-2{width:calc(16.665% - 20px);}
form .ashade-row.ashade-small-gap{flex-direction:column;margin:0}
form .ashade-row.ashade-small-gap .ashade-col.col-4{width:100%;margin:0;}
/* --- Media Grid --- */
.ashade-grid{margin:-15px;display:flex;flex-wrap:wrap;}
.ashade-grid .ashade-grid-item{margin:15px;}
.ashade-grid-1cols .ashade-grid-item{width:calc(100% - 30px);}
.ashade-grid-2cols .ashade-grid-item{width:calc(50% - 30px);}
.ashade-grid-3cols .ashade-grid-item{width:calc(33.33% - 30px);}
.ashade-grid-4cols .ashade-grid-item{width:calc(25% - 30px);}
.ashade-grid-5cols .ashade-grid-item{width:calc(20% - 30px);}
.ashade-gallery-bricks{margin:-15px;}
.ashade-gallery-bricks .ashade-gallery-item{margin:15px;}
.ashade-gallery-bricks.is-1x2 .ashade-gallery-item{width:calc(50% - 30px);}
.ashade-gallery-bricks.is-1x2 .ashade-gallery-item:nth-child(3n){width:calc(100% - 30px);}
.ashade-gallery-bricks.is-2x3 .ashade-gallery-item.is-large{width:calc(50% - 30px);}
.ashade-gallery-bricks.is-2x3 .ashade-gallery-item.is-small{width:calc(33.33% - 30px);}

}
/* Tablet Portrait Styles */
@media only screen and (max-width:960px){body.ashade-layout--vertical .ashade-to-top-wrap.ashade-mobile-b2t{display:flex;}
body .hide-on-desktop,body .hide-on-laptop,body .hide-on-tablet-land,body section.ashade-section.hide-on-desktop,body section.ashade-section.hide-on-laptop,body section.ashade-section.hide-on-tablet-land{display:block;}
body .hide-on-tablet-port,body section.ashade-section.hide-on-tablet-port{display:none;}
body.ashade-layout--vertical .ashade-to-top-wrap{display:none;}
.hide-on-tablet-port.hide-on-phone{display:none!important;}
.ashade-row-fullheight{min-height:0!important;}
/* --- Media Grid --- */
#ashade-home-works .ashade-grid-2cols .ashade-grid-item,#ashade-home-works .ashade-grid-3cols .ashade-grid-item,#ashade-home-works .ashade-grid-4cols .ashade-grid-item,#ashade-home-works .ashade-grid-5cols .ashade-grid-item{width:calc(50% - 30px);}
/* --- Grid --- */
.ashade-row:not(.ashade-keep-on-tablet){display:flex;flex-direction:column;margin:0;}
.ashade-row:not(.ashade-keep-on-tablet) .ashade-col{margin:0 0 30px 0;}
form .ashade-row:not(.ashade-keep-on-tablet) .ashade-col{margin:0;}
.ashade-row:not(.ashade-keep-on-tablet) .ashade-col:last-child{margin:0;}
.ashade-grid.ashade-small-gap{margin:10px 0;}
.ashade-row:not(.ashade-keep-on-tablet) .ashade-col.col-12,.ashade-row:not(.ashade-keep-on-tablet) .ashade-col.col-9, .ashade-row:not(.ashade-keep-on-tablet) .ashade-col.col-8, .ashade-row:not(.ashade-keep-on-tablet) .ashade-col.col-6, .ashade-row:not(.ashade-keep-on-tablet) .ashade-col.col-4, .ashade-row:not(.ashade-keep-on-tablet) .ashade-col.col-3, .ashade-row:not(.ashade-keep-on-tablet) .ashade-col.col-2,.ashade-small-gap.ashade-grid-1cols .ashade-grid-item,.ashade-small-gap.ashade-grid-2cols .ashade-grid-item,.ashade-small-gap.ashade-grid-3cols .ashade-grid-item,.ashade-small-gap.ashade-grid-4cols .ashade-grid-item,.ashade-small-gap.ashade-grid-5cols .ashade-grid-item,.ashade-no-gap.ashade-grid-1cols .ashade-grid-item,.ashade-no-gap.ashade-grid-2cols .ashade-grid-item,.ashade-no-gap.ashade-grid-3cols .ashade-grid-item,.ashade-no-gap.ashade-grid-4cols .ashade-grid-item,.ashade-no-gap.ashade-grid-5cols .ashade-grid-item{width:100%;}
/* --- Albums Listing --- */
.ashade-albums-carousel .ashade-album-item__title h2{font-size:30px;line-height:35px;}
.ashade-albums-carousel .ashade-album-item__title h2 span{font-size:12px;line-height:14px;}
.ashade-albums-carousel-wrap .ashade-album-item__title:before{top:17px;}
.ashade-slider-prev{left:40px;}
.ashade-slider-next{right:40px;}
/* --- Media Grid --- */
.ashade-grid-2cols .ashade-grid-item,.ashade-grid-3cols .ashade-grid-item,.ashade-grid-4cols .ashade-grid-item,.ashade-grid-5cols .ashade-grid-item{width:calc(50% - 30px);}
/* --- Elements --- */
.ashade-testimonials-grid{margin:-20px -15px;}
.ashade-testimonials-grid .ashade-testimonials-item{margin:20px 15px;width:calc(50% - 30px);}

/* --- Grid Filter --- */
.ashade-filter-wrap{margin-bottom:40px;}
.ashade-filter-wrap a{margin:0 10px;}
}
/* Mobile Phone Styles ------------------- */
@media only screen and (max-width:767px){
.ashade-maintenance-wrap .ashade-to-top-wrap.ashade-back-wrap{display:none;}
.ashade-mobile-title-wrap{display:block;margin:60px 0 30px 0;}
body .hide-on-desktop,body .hide-on-laptop,body .hide-on-tablet,body .hide-on-tablet-land,body .hide-on-tablet-port,body section.ashade-section.hide-on-desktop,body section.ashade-section.hide-on-laptop,body section.ashade-section.hide-on-tablet,body section.ashade-section.hide-on-tablet-land,body section.ashade-section.hide-on-tablet-port{display:block;}
body .hide-on-phone,body section.ashade-section.hide-on-phone{display:none;}
/* --- Grid --- */
.ashade-row{display:flex;flex-direction:column;margin:0;}
.ashade-col{margin:0 0 30px 0;}
.ashade-col:last-child{margin:0;}
.ashade-row.ashade-small-gap,.ashade-grid.ashade-small-gap{margin:0 0 0 0;}
.ashade-row.ashade-small-gap .ashade-col:last-child,.ashade-grid.ashade-small-gap .ashade-col:last-child{margin:0;}
.ashade-row.ashade-small-gap .ashade-col{margin:0 0 10px 0;}
.ashade-col.col-12,.ashade-col.col-9, .ashade-col.col-8, .ashade-col.col-6, .ashade-col.col-4, .ashade-col.col-3, .ashade-col.col-2,.ashade-small-gap.ashade-grid-1cols .ashade-grid-item,.ashade-small-gap.ashade-grid-2cols .ashade-grid-item,.ashade-small-gap.ashade-grid-3cols .ashade-grid-item,.ashade-small-gap.ashade-grid-4cols .ashade-grid-item,.ashade-small-gap.ashade-grid-5cols .ashade-grid-item,.ashade-no-gap.ashade-grid-1cols .ashade-grid-item,.ashade-no-gap.ashade-grid-2cols .ashade-grid-item,.ashade-no-gap.ashade-grid-3cols .ashade-grid-item,.ashade-no-gap.ashade-grid-4cols .ashade-grid-item,.ashade-no-gap.ashade-grid-5cols .ashade-grid-item,.ashade-contact-form .ashade-row.ashade-small-gap .ashade-col.col-4{width:100%;}
.ashade-col:empty{display:none;}
.ashade-contact-form .ashade-row.ashade-small-gap .ashade-col.col-4{margin:0;}
.ashade-smooth-scroll .ashade-content-scroll.is-centered{flex-direction:column;}
/* --- Media Grid --- */
.ashade-grid{margin:-15px 0;display:flex;flex-wrap:wrap;}
.ashade-grid .ashade-grid-item{margin:15px 0;}
.ashade-grid-1cols .ashade-grid-item,.ashade-grid-2cols .ashade-grid-item,.ashade-grid-3cols .ashade-grid-item,.ashade-grid-4cols .ashade-grid-item,.ashade-grid-5cols .ashade-grid-item{width:100%;}
.ashade-gallery-bricks{margin:-5px;}
.ashade-gallery-bricks .ashade-gallery-item{margin:5px;}
.ashade-gallery-bricks.is-1x2 .ashade-gallery-item{width:calc(50% - 10px);}
.ashade-gallery-bricks.is-1x2 .ashade-gallery-item:nth-child(3n){width:calc(100% - 10px);}
.ashade-gallery-bricks.is-2x3 .ashade-gallery-item.is-large{width:calc(50% - 10px);}
.ashade-gallery-bricks.is-2x3 .ashade-gallery-item.is-small{width:calc(33.33% - 10px);}
/* --- Albums --- */
.ashade-filter-wrap > a{display:none;}
.ashade-mobile-filter-wrap{display:block;}
.ashade-albums-carousel-wrap .ashade-album-item__inner{margin-right:20px;}
.ashade-albums-carousel-wrap .ashade-album-item .ashade-button{bottom:20px;right:20px;}
.ashade-albums-carousel-wrap .ashade-albums-carousel.is-medium .ashade-album-item a.ashade-button{height:40px;padding:10px 20px;font-size:12px;}
.ashade-albums-carousel-progress{display:none;}
.ashade-albums-template--ribbon .ashade-albums-carousel-wrap .ashade-albums-carousel.is-vertical{width:calc(100% - 40px);margin:0 auto;}
.ashade-albums-template--ribbon .ashade-albums-carousel-wrap .ashade-albums-carousel.is-vertical div.ashade-album-item .ashade-album-item__inner{margin-bottom:20px;}
.ashade-albums-slider-wrap .ashade-album-item__explore, body.ashade-layout--vertical .ashade-albums-slider-wrap .ashade-album-item__explore, body.ashade-layout--vertical .ashade-back-wrap{margin:0;left:calc(100% - 60px);}
body.ashade-layout--horizontal .ashade-albums-slider-wrap .ashade-album-item__explore, body.ashade-layout--horizontal .ashade-back-wrap{margin:0;}
.ashade-albums-slider-wrap .ashade-album-item__title h2 span{line-height:18px;margin:0;}
.ashade-albums-slider .ashade-album-item__explore span, .ashade-home-link span:first-child, .ashade-back span:first-child{font-size:10px;margin-bottom:0;}
.ashade-home-link span:first-child{margin-bottom:1px;}
.ashade-back-wrap.is-loaded .ashade-back:not(.is-to-top) span:first-child, .has-to-top .ashade-back-wrap.is-loaded .ashade-back.is-to-top span:first-child{margin-bottom:0;}
.ashade-albums-slider-wrap .ashade-album-item__title.is-loaded h2 span{font-size:12px;line-height:15px;margin:0 0 1px 0;}
.ashade-albums-slider-wrap .ashade-album-item__title.is-loaded h2,.ashade-albums-slider .ashade-album-item__explore,.ashade-home-link span:last-child,.ashade-back span:last-child{font-size:24px;line-height:29px;}
.ashade-back-wrap:before,.ashade-home-link-wrap:before{top:18px;}
body.ashade-layout--horizontal .ashade-back-wrap:before,.ashade-albums-slider-wrap .ashade-album-item__explore:before, .ashade-albums-slider-wrap .ashade-album-item__title:before{top:16px;}
.ashade-slider-prev{left:20px;}
.ashade-slider-next{right:20px;}
.ashade-albums-template--slider .ashade-page-title-wrap{display:flex;left:20px;}
.ashade-albums-slider-wrap .ashade-album-item__title, .ashade-page-title-wrap{left:20px;}
.ashade-page-title-wrap h1{font-size:40px;line-height:45px;}
.ashade-page-title-wrap h1 span{font-size:12px;line-height:18px;}
.ashade-albums-template--slider .ashade-page-title-wrap:before{top:20px;}
.ashade-albums-template--slider .ashade-page-title-wrap h1{font-size:30px;line-height:35px;}
.ashade-mobile-title-wrap:before{top:18px;}
/* --- Typography --- */
.is-dropcap::first-letter,h1{font-size:40px;line-height:45px;margin:0 0 45px 0;}
.is-dropcap::first-letter{margin:4px 15px 0 0;font-size:50px;line-height:55px;}
#ashade-coming-soon h2{font-size:35px;line-height:40px;}
#ashade-coming-soon span{font-size:12px;line-height:24px;}
.ashade-service-item__content-inner h3{font-size:24px;line-height:29px;}

.ashade-mobile-title-wrap h1{font-size:30px;line-height:35px;display:block;text-align:center;}
.ashade-mobile-title-wrap h1 > span{transform:translate(0);text-align:center;justify-content:center;}
/* --- Content --- */
body.ashade-layout--vertical .ashade-content{width:100%;}
.ashade-more-categories{max-width:calc(100% - 40px);}
aside#ashade-aside{max-width:calc(100% - 40px);}
aside#ashade-aside .ashade-aside-inner{width:100%;}
.ashade-aside-close{transform:translateX(calc(-100% - 20px)) rotate(-90deg);}
.ashade-aside-content{padding:40px 20px;}
.ashade-widget{margin:0 0 60px 0;}
.ashade-page-title-wrap{display:none!important;}
.ashade-content{margin:auto;}
.ashade-content{padding-top:40px;padding-bottom:40px;}
#ashade-home-contacts p.ashade-intro{margin-bottom:40px;}
#ashade-home-works p.ashade-intro,section.ashade-section{margin-bottom:80px;}
p.ashade-intro{margin-bottom:40px;}
.ashade-contact-form__footer{flex-direction:column-reverse;justify-content:flex-end;}
.ashade-contact-form__response span{display:block;padding-top:10px;}
body.ashade-layout--vertical .ashade-back-wrap:before{top:17px;}
}
