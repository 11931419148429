.dm-hand{
    position: absolute;
    bottom: 50%;
    right: 90px;
    @include media-breakpoint-down(md){
        display: none;
    }
}
.dm-title-cove{
    position: absolute;
    right: 92%;
    top: -100%;
    max-width: 35%;
    @include media-breakpoint-down(sm){
        display: none;
    }
}
.dm-h3{
    margin-bottom: 30px;
    span{
        color: #040b1a;
        position: relative;
        &:after{
            content: '';
            display: block;
            background-color: #fff;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            transform: rotate(-4deg);
        }
    }
}
.dm-arrow{
    display: block;
    margin: -10% 0px;
    .digital-arrow{
        display: inline-block;
        transform: scale(0.8);
        height: auto;
        max-width: 15%;
        &.dm-arrow1{
            margin-top: 20%;
        }      
        &.dm-arrow2{
            margin-top: 10%;
        } 
        &.dm-arrow4{
            margin-top: 05%;
        } 
        &.dm-arrow5{
            margin-top: 10%;
        } 
        &.dm-arrow6{
            margin-top: 20%;
        } 
    }    
}
.dm-plus {
    position: absolute;
    right: 50px;
    bottom: 100px;
    z-index: -1;
    max-width: 20%;
    height: auto;
}
.dm-rounds-left {
    position: absolute;
    left: 0px;
    bottom: 50%;
    z-index: -1;
    max-width: 20%;
    height: auto;
}
.dm-rounds-right {
    position: absolute;
    right: -55px;
    bottom: 50%;
    z-index: -1;
    max-width: 20%;
    height: auto;
}