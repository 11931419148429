.inner-page.android-new{
  @include media-breakpoint-up(xl) {
    padding-top: 0;
  }
  font-family: $poppins;
  p{
    font-size: 24px;
    @include fluid-type($min_width, $max_width, 18px, 26px);
    letter-spacing: 0;
    font-family: $poppins;
    font-weight: 500;
  }
}
.and-new-sec{  
  font-family: $poppins; 
  @include media-breakpoint-up(xl) {
    height: 100vh;
  }
  width: 100%;
  background-attachment: fixed;
  position: relative;
  @include media-breakpoint-down(md) {
    height: auto;
  }
  h2{
    @include fluid-type($min_width, $max_width, 32px,  54px);
    font-family: $poppins;
    font-weight: 700;
    margin-bottom: 26px;
  }
  .container{
    @include media-breakpoint-up(xl) {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      position: absolute;
      margin-left: auto;
      top: 50%;
      left: 50%;
      @include transform (translate(-50%,-50%));
      @media only screen and (max-width: 1560px) and (min-width: 1200px)  {
        min-width: 100%; 
      }      
    }    
  }
}
.and-list{
  display: table;
  margin: 0px auto;
  li{
    padding:0px 0px 10px 36px;
    border-bottom: 1px solid #fff;
    &:before{
      width: 22px;
      height: 22px;
      left: 0px;
      top: 9px;
      background: transparent;
      border: 5px solid #fff;
    }
    &:last-child{      
      border: none;      
    }
  }
}
.and-new1{
  h1{
    @include fluid-type($min_width, $max_width, 32px,  54px);
    font-family: $poppins;
    font-weight: 700;
    margin-bottom: 26px;
  }
  .and-dots1{
    position: absolute;
    left: -80px;
    @include media-breakpoint-down(lg) {
      top: 0;
    }
  }
  .and-dots2{
    position: absolute;
    right: 0;
    transform: rotate(180deg);    
  }
  .and-half-round{
    position: absolute;
    left: 25%;
    bottom: -11%;
    z-index: 0;
  }
  .and-round{
    position: absolute;
    right: 0;
    bottom: 60px;
    z-index: -1;
    @media only screen and (max-width: 1560px) and (min-width: 1200px)  {
      max-width: 300px;
    } 
  }
  .and-right-img{
    position: absolute;
    right: 0px;
    bottom: -277px;
    z-index: 2;   
    @include media-breakpoint-down(lg) {
      position: static;
      max-width: 80%;
      margin: 0px auto;
      display: table;
    }
    @media only screen and (max-width: 1560px) and (min-width: 1200px)  {
      bottom: -167px;
      max-width: 300px;
    } 
  }
}
.and-new2{
  background-color: #fff;
  .container{
    filter: invert(1);
  }
  .and-sec2-right-corner {
    position: absolute;
    right: 0;
    max-width: 18%;
    top: 0;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .and-btm-angle {
    filter: invert(1);
  }
  .sec2-part1{
    position: relative;
    &:after {
      content: '';
      height: 60%;
      position: absolute;
      width: 1px;
      background: #b2b2b2;
      right: 0;
      top: 10%;
      @include media-breakpoint-down(lg) {
        position: static;
        width: 100%;
        height: 1px;
        display: block;
        margin-bottom: 40px;
      }
    }
  }
  .and-sec2-part{
    p{
      font-size: 16px;
      font-weight:400;
    }
    .sec2-part1, .sec2-part2{
      @include media-breakpoint-up(lg) {
        padding: 0px 70px;
      }
    }
  }
  .and-sec2-lastp{
    p{
      font-size: 18px;
      line-height: 2;
    }
    hr{
      width: 150px;
      background: #909090;
    }
  }
}
.and-new3{
  .and-corner1{
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
    max-width: 22%;
  }
  .and-corner2{
    position: absolute;
    right: 0;
    top: 0;
    max-width: 22%;
  }
  .and-rshadow {
    position: absolute;
    right: 0;
    max-height: 100%;
    top: 10%;
    z-index: -1;
  }
  .right-hand {
    position: absolute;
    right: 0;
    top: 36%;
    z-index: 1;
    max-width: 18%;
  }
  .and-mob-img{
    position: relative;
    right: -31px;
    transform: scale(1.2);
    transform-origin: right center;
    margin-top: -40px;
  }
}
.and-new4{
  background-color: #fff;
  .container{
    z-index: 2;
  }
  &:after{
    content: '';
    background: url(../../img/and-brm-sahdow.png) repeat-x;
    height: 50%;
    width: 100%;
    display: block;
    position: absolute;
    background-size: 100% 100%;
    bottom: -1px;
    z-index: 0;
    @include media-breakpoint-down(lg) {
      height: 10%;
    }
  }
  .container{
    filter: invert(1);
  }
  .and-dots1{
    position: absolute;
    left: 80px;
    filter: invert(1);
    transform: rotate(180deg);
    @include media-breakpoint-down(lg) {
     top: 0;
     left: 0;
    }
  }
  .and-dots2{
    filter: invert(1);
    position: absolute;
    right: 80px;   
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}