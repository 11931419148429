/**** Checkout page css*****/
.woocommerce-checkout {
  #customer_details {
    .col-1 {
      float: none;
      width: auto;
      max-width: initial;
      flex: unset;
    }
    .col-2 {
      display: none;
    }
  }
  #payment {
    background: #161616;
    ul.payment_methods li:before {
      display: none !important;
    }
  }
  div.payment_box::before {
    border-bottom-color: #2d2d2d;
  }
}
.woocommerce-error, .woocommerce-info, .woocommerce-message {
  background: #2d2d2d;
}
.woocommerce button.button.alt {
  @extend .btn-primary;
  &:hover{
    background-color: $black-color;
  }
}
.form-row input.input-text {
  color: $black-color;
}
.woocommerce-order-details {
  display: none;
}
.woocommerce{
  ul.order_details li::before, .mwb-pr-popup-tab-listing li::before {
    display: none;
  }
  .woocommerce-customer-details address {
    border: none;
    padding: 0px;
  }
} 
.mwb-crp-referral-table-wrapper .mwb-crp-referral-table tbody td {
  background: #202034;
}
.woocommerce-MyAccount-navigation-link--orders,
.woocommerce-MyAccount-navigation-link--downloads,
.woocommerce-MyAccount-navigation-link--edit-address,
.woocommerce-MyAccount-navigation-link--dashboard {
  display: none;
}
.payment_method_paypal .about_paypal {
  display: none !important;
}
.woocommerce .col2-set,
.woocommerce-page .col2-set {
  display: flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.woocommerce .col2-set .col-1,
.woocommerce .col2-set .col-2 {
  @include media-breakpoint-up(md) {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.cart_item .product-name .product-quantity {
  display: none;
}
#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
  background-color: #282828;
}
.woocommerce #payment .form-row select,
.woocommerce-page #payment .form-row select {
  background-color: transparent;
  padding: 6px;
  border-radius: 6px;
  min-width: 180px;
  border-color: $white-color;
  color: $white-color;
}
.woocommerce #payment .form-row select option,
.woocommerce-page #payment .form-row select option {
  color:$white-color;
}
.woocommerce form .form-row.woocommerce-validated select {
  border-color:$white-color;
}
.input-text {
  min-width: 270px;
  border-radius: 4px;
  border: none;
  padding: 5px;
}
div#customer_details .col-1 {
  padding-left: 0px;
}
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  max-width: 200px;
}
.woocommerce form .form-row-first,
.woocommerce-page form .form-row-first {
  width: auto;
}
.woocommerce form .form-row label,
.woocommerce-page form .form-row label {
  min-width: 208px;
  line-height: 1;
}
.checkout_coupon.woocommerce-form-coupon .form-row-last {
  float: none;
}
.woocommerce-form-login {
  .form-row {
    float: none !important;
  }
}
.woocommerce-order {
  .woocommerce-customer-details {
    display: none;
  }
}
.woocommerce-privacy-policy-text {
  display: none;
}
#customer_login .u-column1 form,
#customer_login .u-column2 form {
  height: calc(100% - 105px);
}
.mwb-pr-popup-tab-wrapper .tab-link.active {
  color: $black-color;
  width: 100%;
  background-color:$white-color !important;
  padding-left: 8px;
}
.mwb-pr-popup-body {
  padding: 0;
  height: auto;
}
.mwb_cpr_logged_wrapper .mwb-pr-popup-tab-wrapper {
  padding: 0px;
  margin: 0px;
  background:$white-color;;
  text-align: left;
}
.mwb-pr-popup-tab-listing li {
  margin-bottom: 0px;
}
.mwb-pr-popup-left-rewards {
  min-width: 100%;
}
.mwb_modal__title {
  border-bottom: 1px solid #ddd;
  padding: 6px;
}
.mwb-pr-popup-tab-listing li p {
  margin-bottom: 5px;
}
.mwb_modal__dialog {
  max-width: 560px;
}
.form-row {
  display: block;
}
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-last,
.woocommerce form .form-row-first,
.woocommerce-page form .form-row-first {
  float: none;
}
.woocommerce form .form-row {
  padding-left: 0px;
}
#account_display_name + span {
  display: block;
  font-size: 10px;
}
.password-input {
  max-width: max-content;
}
.show-password-input {
  color: #8f8f8f;
}
form.edit-account fieldset {
  margin-top: 30px;
}
.woocommerce-page{
  .pagination-single-inner{
    display: none !important;
  }
}
.woocommerce button.button {
  @extend .btn;
  @extend .btn-primary;
}
tr.cart-subtotal {
  display: none;
}
.mwb-crp-referral-table-wrapper #mwb-crp-referral-table_filter input[type="search"],
.mwb-crp-referral-table-wrapper #mwb-crp-referral-table_length select {
  padding: 5px;
  @include border-radius(4px);
  background-color: transparent;
}
#mwb-crp-referral-table_filter {
  display: none !important;
}
.mwb-crp-referral-table-wrapper #mwb-crp-referral-table_length select option {
  color: $black-color;
}
.mwb-crp-referral-table-wrapper .mwb-crp-referral-table {
  margin-bottom: 18px;
}
#mwb-crp-referral-table {
  margin-bottom: 16px;
}
#mwb-crp-referral-table_paginate span a {
  background-color: #7794d8;
}
.mwb-crp-referral-table-wrapper .mwb-crp-referral-table thead th {
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}
.woocommerce-MyAccount-content mark {
  background-color: #1c1c30;
}
.woocommerce-MyAccount-content {
  fieldset {
    .mwb_cpr_heading,
    .mwb_crp_referral_link,
    code,
    .mwb_crp_wrapper_button {
      display: none;
    }
  }
  .woocommerce-customer-details {
    padding: 0px;
    .woocommerce-column__title {
      font-size: 20px;
    }
  }
}
.mwb_crp_wrapper_button {
  margin-top: 16px;
}
.woocommerce-MyAccount-content a:nth-child(2) {
  display: none;
}
small.woocommerce-password-hint {
  color: #dddddd;
}
.woocommerce-error,
.woocommerce-message,
.woocommerce-info {
  li {
    margin: 0px;
    &::before {
      display: none;
    }
  }
}
li.woocommerce-order-overview__email.email {
  display: none;
}
.woocommerce form .form-row .required {
  display: none;
}
.col-1,
.col-2 {
  @include media-breakpoint-down(md) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.inner-page{
  .woocommerce-billing-fields{
    h3{
      @extend h4;
    }
  }
  #order_review_heading{
    @extend h4;
  }
}
#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box{  
  color:$white-color;
  &:before{
    border: 1em solid #282828;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
.woocommerce div.product form.cart div.quantity {
  display: none;
}
.product .product_meta {
  display: none;
}
.product {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.woocommerce{
  ul.products{
    @extend .list-unstyled;
    @extend .row;
    li.product{
      margin:0px;
      float:none;
      @extend .col-md-4;  
      @include media-breakpoint-down(md){
        width: 100% !important;
      } 
      .product-list-wrap{
        height: 100%;
        border: 1px solid #333333;
        padding: 15px;
        display: flex;
        flex-direction: column;
        .woocommerce-loop-product__title{
          font-weight: 500;
        }
        .add_to_cart_button{
          @extend .btn;
          @extend .btn-primary;
          margin-top: auto;   
        }   
      }     
    }
  }
} 
.page-template-page-checkout{
  .styled-separator, .pagination-single-inner{
    display: none!important;
  }
}
.woocommerce-LoopProduct-link{
  pointer-events: none;
  img {
    padding: 12%;
    background: #d5d5d5;
    border-radius: 6px;
  }
}
.woocommerce{
  .inner-page{
    margin-bottom: 60px;
  }
}
.widget {
  border: 1px solid #333333;
  margin-bottom: 22px;
  .widget-title{
    border-bottom: 1px solid #333333;
    font-size: 23px;
    font-weight: 600;
    padding: 8px 12px;
  }
  .product-categories{
    padding: 8px 12px;
    margin-bottom: 0;
  }
}