.smm-wave-left, .smm-wave-right{
    position: absolute;
    height: 100%;
    text-align: center;
    svg, img{
        margin-bottom: 75%; 
               
    }
    @include media-breakpoint-down(lg){
        position: static;
        margin: 0px auto;
        display: inline-block;
        svg, img{
            max-width: 20%;
            height: auto;
            margin: 3%;
        }
        br{
            display: none;
        }
    }    
}
.smm-wave-left{   
    left:10%;
    @include media-breakpoint-down(md){
        left: auto;
    }
}
.smm-wave-right{
    right: 10%;
    @include media-breakpoint-down(md){
        right: auto;
    }
}
.rotate-title{
    transform:rotate(-4deg) ;
    margin-bottom: 40px;
}
.smm-like-btm{
    transform: rotate(90deg);
    position: relative;    
    max-width: 20%;
    height: auto;
    margin: 0px auto;
    display: table;
}
.smm-hands{
    position: absolute;
    right: 0;
    top: 0;
    max-width: 30%;
    height: auto;
}