.struts-squre-border{
    position: relative;
    display: table;
    margin: 0px auto;
    .struts-squre{
        position: absolute;
        height: 100%;
        width: 100%;
        border: 2px solid #d5ff20;
        right: -64%;
        top: -39%;
    }
}