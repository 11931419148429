.inner-page.portfolio {
  padding: 150px 0px 0px;
  @include media-breakpoint-down(md) {
    padding: 80px 0px 0px;
  }
  .portfolio-left-shadow {
    position: absolute;
    top: 0%;
    right: 0;
    z-index: -1;
    max-width: 20%;
  }
  h1 {
    font-weight: 900;
    @include fluid-type($min_width, $max_width, 60px, 196px);
  }
  h5 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 70px;
  }
}
.portfolio-filters {
  margin-bottom: 90px;
  li {
    border-radius: 100px;
    border: 2px solid $black-color;
    display: inline-block;
    padding: 5px 24px !important;
    margin: 0px 20px 20px 0px !important;
    cursor: pointer;
    @include fluid-type($min_width, $max_width, 18px, 20px);
  }
}
.portfolio-grid {
  overflow: visible !important;
  .port-content {
    border-radius: 15px;
    overflow: hidden;
    @include transition(0.4s);
    margin-bottom: 30px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 10px 20px 8px rgba(97, 130, 162, 0.46);
    }
    img {
      width: 100%;
    }
  }
}
.portver-line {
  transform: rotate(-90deg) scaleX(-1) translate(-20%, 0px);
  position: absolute;
  top: 50%;
  transform-origin: top left;
  font-family: $stalinist;
  left: 5%;
  z-index: -1;
  @include fluid-type($min_width, $max_width, 15px, 18px);
  span {
    transform: scaleX(-1);
    display: block;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.portfolio-squres {
  position: absolute;
  top: 0%;
  right: 5%;
  max-width: 10%;
  height: auto;
  z-index: -1;
  &.left-squre {
    left: 5%;
    right: auto;
  }
}
// Single Portfolio
.port-item {
  padding: 70px 0;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }
}
.single-port-img-parent {
  position: relative;
  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }
  .single-port-img {
    width: 100%;
  }
  .single-port-label {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translate(0, -50%);
    background: #caef36;
    color: $black-color;
    text-transform: uppercase;
    padding: 3px 10px;
    font-weight: 600;
    font-size: 27px;
  }
}
.single-port-desc {
  text-transform: uppercase;
  img{filter:invert(1);}
  @include fluid-type($min_width, $max_width, 18px, 25px);
  span {
    display:block;
    line-height:1.2;
    margin-bottom:10px;
    em {
      text-decoration: none;
      -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
      -webkit-mask-size: 200%;
      animation: shineWave 2s linear infinite;
      padding: 0px 7px;
      font-weight: 500;
      background-color:#af0000;
    }
  }
  strong {
    //color:$red;
    font-weight: normal;
  }
}
.screen {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  img.scroll {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 0;
    margin: auto;
    left: 0;
    right: 0;
    padding: 0;
  }
}

@keyframes shineWave {
  from { -webkit-mask-position: 150%; }
  to { -webkit-mask-position: -50%; }
}