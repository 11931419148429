
.magento-sec1{
    position: relative;    
    .magento-rounds{
        position: absolute;
        right: 0;
        bottom: 50%;
        z-index: -1;
        max-width:20%;
        height: auto;
        @include media-breakpoint-down(lg){
            bottom: auto;
            top: 0;
        }
    }
}
.magento{
    position: relative;
    section{
        padding: 70px 0px;
        &.magento-sec3{
            padding: 140px 0px;  
        }
        @include media-breakpoint-down(md){
            padding: 30px 0px;
            &.magento-sec3{
                padding: 70px 0px;  
            }
        }
    }
    h1, h2{
        margin-bottom: 24px;
    }
}
.nes-dolor{
    font-family: $stalinist;
    font-size: 28px;
    @include fluid-type($min_width, $max_width, 9px, 40px);
}
.mage-payment{
    max-width: 100%;
    height: auto;
    position: relative;
    @include media-breakpoint-up(xl){
        transform: scale(0.85);
    }
    @include media-breakpoint-down(md){
        margin: 70px auto 0px auto;
        max-width: 460px;
    }
    .mage-pay-hidden{
        visibility: hidden;
        width: 100%;
        height: auto; 
    }
    img:not(.mage-pay-hidden){
        position: absolute;
        height: auto;
        z-index: 0;
    }
    .mage-pay1{        
        width: 12%;
        right: 33%;
        top: 0;
    }
    .mage-pay2{        
        width: 8%;
        left: 0;
        top: 17%;
    }
    .mage-pay3{        
        width: 15%;
        right: 0;
        top: 13%;
    }
    .mage-pay4{        
        width: 12%;
        right: 22%;
        top: 35%;
    }
    .mage-pay5{        
        width: 49%;
        left: 0;
        top: 46%;
    }
    .mage-pay6{        
        width: 38%;
        right: 6%;
        bottom: 23%;    
    }
    .mage-pay7{        
        width: 24%;
        left: 8%;
        bottom: 10%;
    }
    .mage-pay8{        
        width: 23%;
        left: 45%;
        bottom: 0;
    }
}
.magento-top-icon{
    position: relative;
    display: table;
    margin: 0px auto;
    width: 45%;
    @include media-breakpoint-down(md){
        width: 65%;
    }
    .magento-t-icon {
        position: relative;
        z-index: 1;       
        width: 100%;
    }
    svg, img:not(.magento-t-icon){
        position: absolute;
        left: 0;
        width: 100%;
        height: auto;
        top: 0;
    }
}
.mage-sale{
    max-width: 100%;
    height: auto;
    position: relative;
    @include media-breakpoint-down(md){
        margin: 0px 0 60px;
    }
    .mage-sale-hidden{       
        width: 100%;
        height: auto; 
        @include media-breakpoint-down(md){
            margin-left: 10%;
        }
    }
    img:not(.mage-sale-hidden){
        position: absolute;
        height: auto;
        z-index: 0;
    }
    .mage-sale1{        
        width: 47%;
        left: 0%;
        top: 0%;
    }
    .mage-sale2{        
        width: 40%;
        left: 42%;
        top: 30%;
    }
    .mage-sale3{        
        width: 45%;
        right: 0%;
        top: 38%;
    }
    .mage-sale4{        
        width: 45%;
        left: 22%;
        bottom: 0;
    }
}
.magento-sec4{
    position: relative;   
    .mage-dolor-left, .mage-dolor-right{
        top:70px;   
        transform:rotate(90deg);
        display:inline-block;
        position:absolute;    
        width: 0px;
        @include media-breakpoint-down(md){
            top:30px;    
        }
    }
    .mage-dolor-left{
        left:30px;
        transform-origin: left;
        @include media-breakpoint-down(md){
            left: 10px; 
        }
    }
    .mage-dolor-right{
        right: 30px;
        transform-origin: right;
        @include media-breakpoint-down(md){
            right: 10px; 
        }
    }
    .magento-logos{
        width: 100%;
        height: auto; 
        position: relative;  
        margin-bottom: 106px;
        @media only screen and (min-width: 2000px){
            transform: scale(0.9);
        }   
        @include media-breakpoint-down(md){
            margin-bottom: 90px;        
            transform: scale(1.3);
            transform-origin: 23% 0;
        }
        .hidden-ele{
            visibility: hidden;
            width: 100%;
            height: auto;
        } 
        img:not(.hidden-ele), svg:not(.hidden-ele){
            position: absolute;
            height: auto;
            top: -12%;
        }
        .mage-logo1{
            z-index: -2;
            width: 60%;
            right: 8%;
        }
        .mage-logo2{
            z-index: -1;
            width: 50%;
            right: 19%;
        }
        .mage-logo3{
            z-index: 0;
            width: 42%;
            right: 39%;
        }
        .mage-logo4{
            z-index: 1;
            width: 42%;
            right: 56%;
        }
    }
    .magento-start{
        max-width: 70%;
        height: auto;
    }
}