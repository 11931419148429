.reputation{
    .rotate-title{
        display: table;
        margin: 0px auto 40px;
    }
    h1{
        @include fluid-type($min_width, $max_width, 43px, 100px); 
    }
}
.rm-hand{
    position: absolute;
    left: 14%;
    top: 50%;
}
.rm-h1-arrow{
    position: absolute;
    transform: rotate(125deg) scale(0.75);
    bottom: 80%;
    right: 0;
}
.rm-h2-hand{
    position: absolute;
    transform: rotate(194deg) scale(0.5);
    bottom: 40%;
    left: 100%;
}
.rm-h2-arrow{
    position: absolute;
    top: 0;
    right: 100%;
    transform: scale(0.8);
}

.rm-plus {
    position: absolute;
    right: 50px;
    top: 100px;
    z-index: -1;
}
.rm-rounds-left {
    position: absolute;
    left: 50px;
    top: 0;
    z-index: -1;
}