.android{
    .and-ele1{
        position: relative;
        .and-ele1-img{
            position: absolute;
            left: 0%;
            top: -60px;
            z-index: -1;
            max-width: 50%;
        }
        .and-ele1-img2{
            position: absolute;
            top: -210px;
            right: 10%;
            z-index: -1;
            @include media-breakpoint-down(sm){
                right: 50%;
                top: -110px;
                transform:none !important;
            }
        }
    }
    .and-ele2{
        position: relative;
        .and-ele2-round{
            position: absolute;            
            left: 0;
            top: 0px;
            z-index: -1;
            transform: translate(-50%, -50%);
        }
        .and-ele2-img{
            position: absolute;
        }
        .and-ele2-img2{            
            animation-name: orbit;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-duration: 2.89016s;
            transform-origin: 45% 45%;
        }
    }
    .and-ele3{
        position: relative;
        .and-ele3-img{
            position: absolute;
            left: 0%;
            top: 20%;
            z-index: -1;
            transform: translate(-30%, -50%);
        }
        .and-ele3-img2{
            position: absolute;
            top: 50%;
            right: 0;
            z-index: -1;
            transform: translate(0%, -50%);
            max-width: 20%;
        }
    }   
}