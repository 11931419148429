@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
// transform Mixins ------//
// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
@include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
@include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
@include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
@include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    -o-transform-origin: $origin;
   -ms-transform-origin: $origin;
-webkit-transform-origin: $origin;
       transform-origin: $origin;
}
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
// Fluid Fonts
$min_width: 350px;
$max_width: 2560px;
$min_font: 18px;
$max_font: 18px;
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);
  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}
/**** text gradiunt****/
.text-gradiunt{
background: -moz-linear-gradient(left,  #03fff6 0%, #efff0b 50%, #ff2771 100%);
background: -webkit-linear-gradient(left,  #03fff6 0%,#efff0b 50%,#ff2771 100%);
background: linear-gradient(to right,  #03fff6 0%,#efff0b 50%,#ff2771 100%);
background-clip: text;
-webkit-text-fill-color: transparent;
display: inline-block;
line-height: 1;
}
/*** orbit route ***/
@include keyframes(orbit) {
  0%   { transform: rotateZ(0deg);}
  100% { transform: rotateZ(-360deg);} 
}
@include keyframes(rotation) {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}
/*** Grediunt Top to Bottom ***/
@include keyframes(grtopbottom)  {
  0% {
    background-position: center 200%;        
  }       
  100% {
    background-position: center 0%;
  }
}