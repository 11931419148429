.ios-shape-rb {
    position: absolute;
    top: 0;
    z-index: -1;
    right: 0;
    @include media-breakpoint-down(sm){
        max-width: 50%;
        @include opacity(0.5);
    }
}
.ios-top-left-gr {
    position: absolute;
    left: 0;
    top: -40%;
}
.ios-solution{
    position: absolute;
    z-index: -1;
    left: -17%;
    bottom: -34%; 
    @include media-breakpoint-down(sm){
        max-width: 50%;
        @include opacity(0.5);
    }
}
.ios-solu-block{
    position: absolute;
    z-index: -1;
    width: 50px;
    height: 50px;
    background-color: #ff005a;
    top: 100%;
    right: 100%;
    @include border-radius(5px);
    @include media-breakpoint-down(sm){
        @include opacity(0.5);
    }
}
.aquamarine-bg{
    background-color: #6fbd76;
    display: table;
    padding: 5px 10px;    
    @include border-radius(12px);
}
.ios-aquamarine-block{       
    position: absolute;
    z-index: -1;
    width: 30px;
    height: 30px;
    background-color: #6fbd76;
    bottom: 0;
    left: 100%; 
    @include border-radius(4px);
    @include media-breakpoint-down(sm){
        @include opacity(0.5);
    }
 }
.ios-bottom-shadow{
    position: absolute;
    bottom: -60%;
    left: -40%;
    z-index: -1;
    transform: rotate(-177deg);
    @include media-breakpoint-down(sm){
        max-width: 50%;
        @include opacity(0.5);
    }
}
.inner-mobileimg{
    .mobile-app-gr{
        @include media-breakpoint-down(md){
            position: absolute;
            left: 0;
            height: calc(100% - 15px);
            top: 3%;
        }
    }
}