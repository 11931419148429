.ppc{
    h1{
        span{
            display: block;
            font-weight: 400;
        }
    }    
}
.ppc-h1{
    position: relative;
    margin-bottom:40px;
    .ppc-h1-gr{
        position: absolute;
        height: 100%;
        top:25%;
        width: 7px;
        left: -20px;
        background: #02fff6;
        background: -moz-linear-gradient(top,  #02fff6 0%, #ffff00 25%, #ff008a 50%, #ffff00 75%, #02fff6 100%);
        background: -webkit-linear-gradient(top,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
        background: linear-gradient(to bottom,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02fff6', endColorstr='#02fff6',GradientType=0 );
        animation-name: grtopbottom;
        background-size:auto 200%;
        animation-duration: 3s; 
        animation-timing-function: linear; 
        animation-direction: normal;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running; 
        @include media-breakpoint-down(sm){
         left: -15px; 
        }
    }
}
.ppc-h2{
    display: table;
    margin: 0px auto;
    position: relative;
   > span{
        background-color: #fff;
        display: table;
        margin: 0px auto;
        > span{
            mix-blend-mode: exclusion;
        }
   }
    small{
        font-size: 100%;
    }
}
.ppc-hand1, .ppc-hand2{
    position: absolute;    
    max-width: 140px;
    height: auto;
    @include media-breakpoint-down(md){
        display: none;
    }
}
.ppc-hand1{
    left: -50%;
    top: 10%;
}
.ppc-hand2{
    left: 50%;
    top: -120px;
    transform: rotate(55deg);
}
.ppc-rounds-right{
    position: absolute;
    right: 0;
    top: 10%;
    z-index: -1;    
    max-width: 30%;
    @include media-breakpoint-down(md){
        top: 0%;
    }
}
.ppc-plus{
    position: absolute;
    right: 50px;
    top: -10%;
    z-index: -1;
    @include media-breakpoint-down(sm){
        position: static;
    }
}
.ppc-rounds-left{
    position: absolute;
    left: 50px;
    top: -10%;
    z-index: -1;
    @include media-breakpoint-down(sm){
        position: static;
        margin: 34px 10px;
    }
}
.ppc-top-arrows{
    position: absolute;
    width: 100%;
    @include media-breakpoint-down(md){
        display: none;
    }
    img{
        position: absolute;
        bottom: calc(100% - 200px);
        right: 100%;
    }
}
.ppc-tit-hand{
    position: absolute;
    left: 97%;
    bottom: 57%;
    transform: rotate(-100deg);
    max-width: 42%;
    height: auto;
}