.inner-page.about {
  padding: 80px 0px 0;
  .about-left-shadow {
    top: 50%;
  }
}
.about-bg {
  position: absolute;
  top: 140px;
  width: 100%;
  height: auto;
  @include opacity(0.7);
  z-index: -1;
}
.about-ver {
  position: absolute;
  transform: rotate(-90deg);
  top: 50%;
  transform-origin: left center;
  left: 7%;
  font-family: $stalinist;
  font-size: 14px;
  @include media-breakpoint-down(lg) {
    left: 0;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
}