.home{
  section{ 
    //padding:100px 0px;
    @include media-breakpoint-down(lg) {
    //  padding:40px 0px;
    }
  }  
}
.border-text{
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $white-color;   
  display: inline-block;
}
/*** Section 1 ***/
section.home-sec1{ 
  padding-top:110px;
  position: relative;   
  @include media-breakpoint-down(md){
    padding-top:120px; 
  }
  .homesecrow1{
    min-height: 80vh;
    @include media-breakpoint-down(md){
      padding: 0px 15px;
      min-height: calc(100vh - 260px);
    }
  }
  .shasona{
    @include media-breakpoint-up(xl){
      transform: scale(1.2);
    }    
    @include media-breakpoint-down(lg){
      margin: -42px auto;
    } 
  }
  .welcome-note{
    font-size: 15px;
    transform: translate(0px, 0px) !important;;
    opacity: 1 !important;
  }
  p{
    @include fluid-type($min_width, $max_width, 20px, 34px);
  }
  .h1-left{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
  .typed-cursor{
    @include opacity(1);
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
    display: inline-block;
    padding-right: 5px;
  }
  .typed-space {
    display: inline-block;
    position: relative;
    #typed-strings {
      display: inline-block !important;
      @include opacity(0);
      padding-right: 50px;
      @media only screen and (min-width: 2000px){ 
        padding-right: 60px;
      }
      visibility: hidden;
      span:not(:first-child) {
        display: none;
      }
    }
  }
  .pink-parent{  
    font-weight: 900; 
    line-height: 1.3;
    @include fluid-type($min_width, $max_width, 46px, 140px); 
    text-transform: uppercase;     
    @extend h1;
    strong{      
      @include media-breakpoint-up(md){
        text-indent: 70px;
      }
    }
    @include media-breakpoint-down(md){
      display: table;
      margin: 0px auto;
    }
  }
  .homesec1-ele{
    position: absolute;
    top: 13%;    
    height: 607px;
    &.homesec1-left{
      transform: rotate(90deg) scaleX(1);
      transform-origin: 0 0;   
      left: 67px;
      h1{
        font-family: $stalinist;
        letter-spacing: 2px;
        white-space: nowrap;
        font-size: 10px;
        text-transform: uppercase;
      }
      @include media-breakpoint-down(lg){
        left: 35px;      
      }
      @include media-breakpoint-down(sm){
        font-size: 1.4vh;
      }
      @media screen and ( max-height: 600px ) {
        font-size: 1.25vh;
      }
    }
    &.homesec1-right{
      left: auto;
      right: 40px;   
      @include media-breakpoint-down(lg){
        right:15px;
      }
    }
    @include media-breakpoint-down(md){    
      height: calc(100% - 40px);
      top: 0%;    
    }
  }
  .home-sec2-gr{
    position: absolute;
    bottom: 6%;
    width: 42%;
    left: 0;
    z-index: -1;
    @media only screen and (min-width: 2000px){ 
      bottom: 0%;
    }
    @include media-breakpoint-down(lg){
      display: none;
    }
  }
  /*#demo-canvas {
    position: absolute;
    top: 0;
    z-index: 0;
  }*/
  .scedule-meeting-sec{
    text-align:center;
    padding: 15px 0px;
    p{
      @include fluid-type($min_width, $max_width, 18px, 22px);
      margin-bottom: 20px;
    }
    h3{font-weight: 800;}
  }
}
/*** End Section 1 ***/
.home-sec22{
  p{
    @include fluid-type($min_width, $max_width, 24px, 34px);
  }
}
/*** Section 2 ***/
.home-sec2{
  position: relative; 
  .homesec2-right-desk, .homesec2-right-mob{  
    width: 100%;
    .homesec2-right-img1{
      position: absolute;
      max-width: 50%;
      right: 0;
      z-index: 1;      
      @include media-breakpoint-down(md){
        right: 15px;
      }
    }
    .homesec2-right-img1{
      height: auto;
    }
    .homesec2-right-img2{
      height: auto;
      max-width: 110%;
      @include media-breakpoint-up(md){
        max-width: 110%;
        width: 100%;
      }
      @include media-breakpoint-down(md){
        margin-bottom: 40px;
      }
    }
    .homesec2-right-block1 {
      height: 37px;
      width: 220px;
      background: #fff;
      display: inline-block;
      position: absolute;
      box-shadow: 30px 50px 82px #191919;
      left: 17%;
      top: 12%;
      z-index: 2;
    }
    .homesec2-right-block2 {
      height: 112px;
      width: 112px;
      background: #fff;
      display: inline-block;
      position: absolute;
      z-index: 1;
      box-shadow: 30px 50px 82px #191919;
      left: 12%;
      top: 16%;
    }
  }   
  h2{
    margin-bottom: 100px;
    text-shadow: 0px 0px 160px #7b7b7b;
    @include media-breakpoint-down(md){
      margin-bottom: 40px;
    }
  }   
  .blockquote{
    position: relative;
    margin-bottom: 90px;
    @include fluid-type($min_width, $max_width, 20px, 28px); 
    span{
      font-family: $font-body;
      font-size: 120px;
      @include fluid-type($min_width, $max_width, 78px, 120px);
      position: absolute;
      &.quorte-start{
        right: 100%;
        display: block;
        line-height: 52px;
      }
      &.quorte-end{  
        line-height: 0.7;   
        margin: 20px;
      }
    }
  }
  .rotatep{
    transform: rotate(-2deg);
  }
  .payment-logos{
    display: table;
    border-bottom:1px solid $white-color;
    margin-top:45px;
    padding-bottom: 20px;
    text-align: center;   
    max-width: 310px;
    @include media-breakpoint-down(md){
      border-bottom:1px solid #ffffff36;
    }
    li{
      display: inline-block;     
      margin-right: 14px;  
      max-width: 20%;
      img{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        cursor: pointer;
        height: auto;
        @include transition(0.3s);
        &:hover{
          -webkit-filter: none;
          filter: none;    
        }
      }  
      &:last-child{
        margin-right: 0px;
      }
      @include media-breakpoint-down(sm){
        max-width: 15%;
        margin: 0px 4px;
      }
    }
  }  
}
/*** End Section 2 ***/
/*** Section 3 ***/
.home-sec3{
  h2{
    margin-bottom: 40px;
    text-shadow: 0px 0px 160px #7b7b7b;
  }
  .web-development{
    transform: scale(1.2);
    height: auto;
    @media only screen and (min-width: 2000px){ 
      transform: scale(1.4);
    }
  }
  .home-web-deve-mobile{
    height: auto;
    @include media-breakpoint-down(lg){
      margin: 0px auto;
      max-width: 400px;
    }
  }
  .web-development-shadow{
    position: absolute;
    z-index: -1;
    left: 0;
    @include opacity(0);
    top: 0;
    transform: scale(1.3);
    @media only screen and (min-width: 2000px){ 
      transform: scale(1.4);
    }
  }
  .web-development-mob{
    height: auto;
    transform: scale(1.3);
  }
  .sec-3-right{
    h2{
      @include fluid-type($min_width, $max_width, 30px, 85px);
    }
  } 
}
/***End Section 3 ***/
/*** Software Scroll ***/
.spacer{
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.software-dev{
  position: relative;
  .scroll-area{
    //width: 400%;
    overflow: hidden;
  }
  .scroll-block {
    //width: calc( 100% / 4);
   // float: left;
    position: relative;
    //height: 100vh;
    //display: flex;
    align-items: center;
    margin-top: 50px;
    @include media-breakpoint-down(md){
      margin-top:30px;
    }
    .container{
      @include media-breakpoint-down(md){
        padding: 0px 15px;
      }
    }
    h4{
      margin-bottom: 7px;
      font-size: 36px;
      font-weight:800;
      //font-family: $monument-ext;
    }
    p{
      @include media-breakpoint-down(md){
        font-size: 17px;
      }
    }
    &.scroll-block1{
      h2{   
        position: relative;
        font-weight: 900;
        display: table; 
        line-height: 0.7;
        margin: 0px auto 40px;  
        //text-shadow: 0px 0px 160px #7b7b7b;  
        @include fluid-type($min_width, $max_width, 56px, 140px); 
        .soft-moving-ele{
          position: absolute;
          right:0px;
          bottom: 0px;
          height: 160%;
          z-index: -1;
        }  
      }
      p{
        position: relative;        
        .moving-span{
          position: absolute;
          background: #0348f9;
          display: block;
          height: 9%;
          mix-blend-mode: difference;
          width: 102%;
          top: 0;
          left: -2%;
        }
      }
      small{
        line-height: 1.4;
        display: block;
        padding-bottom: 20px;
        width: 409px;
        max-width: 100%;
        font-size: 10px;
        &::after{
          content: '';
          height: 12px;
          width: 250px;
          background: #ffffff;
          display: block;
          margin-top: 28px;
        }
      }      
    }    
    &.scroll-block4{
      .soft-gr-round{
        position: absolute;
        bottom: 0;
        right: 0;
        @include media-breakpoint-down(md){
          top: 80%;
          max-width: 60%;
        }
        @include media-breakpoint-down(md){
          bottom:0%;
          max-width: 40%;
        }
      }
    }
  }
  .soft-border{
    border: solid #fff;
    padding: 60px 40px 40px;
    background: url(../../img/soft-top.png) no-repeat;
    background-size: calc(100% + 3px);
    background-position: top center;
    border-width: 0px 1px 1px 1px;
    @include media-breakpoint-down(md){
      padding: 20px;
    }  
  }
  img.scroll-line1 {
    position: absolute;
    mix-blend-mode: exclusion;
    bottom: 0;
    right: calc(100% - 15px);
  }
  .soft-round-parent{
    @include media-breakpoint-down(md){
      position: absolute;
      height: 250%;
      width: 250%;
      transform: scale(0.4);
      bottom: -75%;
      right: -75%;
    }
  }
  .soft-round{
    border:1px solid #fff;
    border-radius:1000px;
    height: 100px;
    width: 100px;
    display: inline-block;
    position: absolute;    
    &.soft-round1{
      top: -81px;
      left: -130px;
      transform: scale(1.3);
      -webkit-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
      -moz-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
      animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
    }
    &.soft-round2{
      right: 25%;
      height: 50px;
      width: 50px;
      top: 30px;
      -webkit-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
      -moz-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
      animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
    }
    &.soft-round3{
      top: 100%;
      left: 50%;
      -webkit-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
      -moz-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
      animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
    }
    &.soft-round4{
      top: 113%;
      left: 0;
      height: 150px;
      width: 150px;
      -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
      -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
      animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
    }
    &.soft-round5{
      top: 103%;
      left: 5%;
      height: 100px;
      width: 100px;
      -webkit-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
      -moz-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
      animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
    }
    &.soft-round6{
      top: 117%;
      left: -60px;
      height: 100px;
      width: 100px;
      -webkit-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
      -moz-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
      animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
    }
    &.soft-round7{
      left: calc(100% - 15px);
      top: 0%;
      height: 120px;
      width: 120px;
      -webkit-animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;
      -moz-animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;
      animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;
    }
  }
  .polygon-patent{
    @include media-breakpoint-down(md){
      position: absolute;
      height: 250%;
      width: 250%;
      transform: scale(0.4);
      bottom: -75%;
      right: -75%;
    }
  }
  .polygons{
    .polygon{
      display: inline-block;
      position: absolute;
      &.polygon1{
        //bottom: 84%;
        left: -12%;
        transform: scale(0.7);
        -webkit-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
        -moz-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
        animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
      }
      &.polygon2{
       // bottom: 90%;
        left: 96%;
        transform: scale(0.5);
        -webkit-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 15s linear infinite;
        -moz-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 15s linear infinite;
        animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 15s linear infinite;
      }
      &.polygon3{
       // bottom: 13%;
        right: 100%;
        transform: scale(0.5);
        -webkit-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 8s linear infinite;
      -moz-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 8s linear infinite;
      animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 8s linear infinite;
      }
      &.polygon4{
        top: 96%;
        left: 50%;
        transform: scale(0.9);
        -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate, spiner 12s linear infinite;
        -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate, spiner 12s linear infinite;
        animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate, spiner 12s linear infinite;
      }
      &.polygon5{
        top: 60%;
        left: 100%;
        transform: scale(0.6);
        -webkit-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 22s linear infinite;
      -moz-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 22s linear infinite;
      animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 22s linear infinite;
      }
      &.polygon6{
        top: 100%;
        right: -3%;
        transform: scale(0.4);
        -webkit-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 10s linear infinite;
      -moz-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 10s linear infinite;
      animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 10s linear infinite;
      }
      &.polygon7{
        top: -1%;
        right: -13%;
        transform: scale(0.3);  
        -webkit-animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 15s linear infinite;
        -moz-animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 15s linear infinite;
        animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 15s linear infinite;
      }
    } 
  }
  .triangle-parent{
    @include media-breakpoint-down(md){
      position: absolute;
      height: 250%;
      width: 250%;
      transform: scale(0.4);
      bottom: -75%;
      right: -75%;
    }
  }
  .triangles{
    .triangle{
      display: inline-block;
      position: absolute;
      &.triangle1{
        //top: -46%;
        left: 14%;
        transform: scale(0.4);  
        -webkit-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
        -moz-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
        animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
      }
      &.triangle2{
       // top: -45%;
        left: 60%;
        transform: scale(0.3) rotate(60deg);  
        -webkit-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 20s linear infinite;
        -moz-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 20s linear infinite;
        animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 20s linear infinite;
      }
      &.triangle3{
        //bottom: -52%;
        left: -10%;
        transform: scale(0.8);
        -webkit-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
        -moz-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;
        animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate, spiner 20s linear infinite;     
      }
      &.triangle4{
        //bottom: -45%;
        left: 42%;
        transform: scale(0.4) rotate(60deg);  
        -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate, spiner 20s linear infinite;
        -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate, spiner 20s linear infinite;
        animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate, spiner 20s linear infinite;
      }
      &.triangle5{
        //bottom: -45%;
        left: 42%;
        transform: scale(0.4) rotate(60deg);  
        -webkit-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 20s linear infinite;
        -moz-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 20s linear infinite;
        animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate, spiner 20s linear infinite;
      }
    }  
  }
}
/***End Software Scroll ***/
/*** Mobile App ***/
.mobile-app-sec{  
  @include media-breakpoint-down(md) { 
    padding-bottom: 0px;
  }
  .home-mobile-app{
    height:auto;
  }
  position: relative;
  .mobile-app-left{
    h6{
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 16px;
      line-height: 1.1;
      margin:-60px 0px 30px 0px;
      font-family: $monument-ext;
      @include media-breakpoint-down(md){
        margin: -10px 0px 0px 0px;
      }
    }
  }
  .mobile-app-gr{
    @include media-breakpoint-down(md){
      position: absolute;
      height: 100%;
      width: auto;
      right: 0;
    }
  }
  .mobile-app-right{
    @include media-breakpoint-down(md){
      margin-top: 40px;
    }  
    h2{
      font-weight: 900;
      margin-bottom: 35px;
      position: relative;
      display: table;
      @include fluid-type($min_width, $max_width, 40px, 110px);      
    }
    small{
      line-height: 1.4;
      display: block;
      padding-bottom: 20px;
      max-width: 100%;
      font-size: 16px;
      &::after{
        content: '';
        height: 12px;
        width: 250px;
        background: #ffffff;
        display: block;
        margin-top: 28px;
      }
    }     
  }    
}
/*** Mobile App End***/
/*** Home Portfolio ***/
.h-portfolio-sec{
  h2{
    @include fluid-type($min_width, $max_width, 32px, 64px);
    font-weight: 700;
    margin-bottom: 20px;
  }
  .home-portfolio{   
    margin: 0px -15px 25px;
    .h-portfolio-item{
      position: relative;     
      img{
        height: auto;
      }
      h5{font-weight: 600;}  
      .stretched-link{
        margin-bottom: 15px;
        display: block;
        overflow:hidden;              
        &:hover{
          img{
            transform: scale(1.05);
            @include transition(0.4s);
          }
        }
      }
    }
  }
}

@include keyframes (blink){
  0% { @include opacity(1); }
  50% { @include opacity(0); }
  100% { @include opacity(1); }
}
@include keyframes (animateBubble){
  0% {margin-top: 400px;}
100% {margin-top: -100%;}
}
@include keyframes (sideWays){ 
  0% {margin-left:0px;}
  100% {margin-left:50px;}
}
@include keyframes (spiner){ 
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
@include keyframes (rotate){ 
  to {transform: rotateY(1turn);}
}
.ads-banner{
  position: relative;
  padding-top: 65px;
  @include media-breakpoint-down(sm){
    padding-top:45px;
  }
  img{
    vertical-align: top;
    height: auto;
  }
  .close-effat{
    position: absolute;
    right: 2px;
    top:2px;
    background:$white-color;
    color: $danger;
    display: inline-block;
    font-size: 16px;
    width: 26px;
    text-align: center;    
    @include media-breakpoint-down(md){
      bottom: 100%;
      top: auto;
    }
  }
  .bomb-smoke {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }
}