.spring-left-shadow, .spring-right-shadow{
    position: absolute;        
    z-index: -1;       
    top:0px;
    width: 10%;
    height: auto;
    @include media-breakpoint-down(sm){
        width: 18%;
    }
}
.spring-left-shadow{
    left: 0;
}
.spring-right-shadow{
    right: 0;
}
.spring-plus{
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 42%;
}

.spring-round{  
    position: relative;
    display: table;
    margin: -15% auto 0;
    height: auto;
    @include media-breakpoint-down(md){
       position: relative;
       right: auto;
       left: 0;
       top: 0;
       bottom: auto;   
    }   
    .spring-rnd1{
        position: absolute;
        @include media-breakpoint-down(md){
            max-width: 100%;
            height: auto;
        }
    }
    .spring-rnd2{            
        animation-name: orbit;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 2.89016s;    
        transform-origin: 45% 45%;  
        @include media-breakpoint-down(md){
            max-width: 100%;
            height: auto;
        }  
    }
}  