body.page-template-private-label{
  background: url(https://cutewallpaper.org/21/star-background-wallpaper/Stars-Backgrounds-,-Best-Background-Images-,-HD-Wallpaper.png);
  background-size: cover;
}

.blink-q{
  animation: blinker 1s linear infinite;
  color: #ec2d61;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
#labelForm{display: none;}
.private-label-form{
  .form-control{
    background-color:transparent;
    border-radius: 0;
    &:focus, &:hover, &:active{
      background-color:transparent;
    }
  }  
}
.label-start-btn{
  background-size:400%;
  animation-duration: 5s;   
  &.btn-submit{
    background: transparent !important;
  } 
}