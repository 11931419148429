.inner-page{
  &.contact-us{
    padding-top:30px;
  }
  h1 {
    position: relative;
    @include fluid-type($min_width, $max_width, 32px, 80px);
    display: table;
    margin: 0px auto 60px;
    @include media-breakpoint-down(md) {
      margin: 0px auto 0px;
    }
    strong {
      font-weight: 800;
    }
    .tool-title-hand {
      position: absolute;
      right: 97%;
      width: 38px;
      bottom: -14px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
.contact-map{
  padding: 3px;
  background: #cdcdcd;
}