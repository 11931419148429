.inner-page {
	padding: 150px 0px 0px;
	@include media-breakpoint-down(md) {
		padding: 80px 0px 0px;
	}
	section {
		padding: 30px 0px;
	}
	> section:nth-child(even) {
		> .container {
			> .row {
				justify-content: flex-end;
			}
		}
	}
	.border-tex {
		-webkit-text-fill-color: transparent;
		-webkit-text-stroke-width: 0.5px;
		-webkit-text-stroke-color: $white-color;
	}
	h1 {
		@include fluid-type($min_width, $max_width, 41px, 100px);
		font-weight: 900;
		line-height: 1;
	}
	h2 {
		@include fluid-type($min_width, $max_width, 47px, 92px);
		font-weight: 900;
		line-height: 1;
		&.h3{
			@include fluid-type($min_width, $max_width, 30px, 70px);
			font-weight: 900;
			line-height: 1;
			margin-bottom: 20px;
		}
		&.h4{
			@include font-sm;
		}
	}
	h3 {
		@include fluid-type($min_width, $max_width, 30px, 70px);
		font-weight: 900;
		line-height: 1;
		margin-bottom: 20px;
	}
}
.seo {
	.seo-shape1 {
		position: relative;
		.seo-shape1-img {
			position: absolute;
			left: 10%;
			top: -50px;
			z-index: -1;
		}
	}
	.seo-shape2 {
		position: relative;
		.seo-shape2-img {
			position: absolute;
			left: -60px;
			top: -100px;
			z-index: -1;
			max-width: 50%;
			height: auto;
		}
		.border-tex {
			br {
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}
	}
	.seo-shape3 {
		position: relative;
		.seo-shape3-img {
			position: absolute;
			right: 0px;
			top: 50%;
			z-index: -1;
			transform: translate(0, -50%);
			max-width: 25%;
		}
	}
	.seo-shape4 {
		position: relative;
		.seo-shape4-img {
			position: absolute;
			left: -10px;
			top: -130px;
			z-index: -1;
			max-width: 42%;
		}
	}
}
