.broadleaf{
    
    section{
        @include media-breakpoint-up(xl){
            padding: 50px 0;
        }
    }
}
.bread-title-gr{
    height: 6px;
    width: 300px;
    display: table;
    margin: 17px auto;
    max-width: 70%;
    @extend .php-gr;
}
.broadleaf1{
    position: relative;
    h1{
        @include fluid-type($min_width, $max_width, 48px, 105px);
    }
    .broadleaf-round{
        position: absolute;
        left: 0px;
        bottom: -120px;
        max-width: 25%;
        height: auto;
    }
}
.bread-block{
display: flex;
    .bread-gr{
        height: 100%;
        min-width: 7px;
        margin-right: 30px;
        @extend .php-gr-ver;
    }
    p{
        font-weight: 100;
        margin-bottom: 0px;
    }
}
