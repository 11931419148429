.mongo-db1{
    position: relative;
    .mongo-left-gr{
        &:before{
            height: 50%; 
            left: -10px;
            top: -3%;
            @include media-breakpoint-down(sm){
                left: 0px; 
            }
        }
    }
    .mongo-round{
        position: absolute;
        right: 0;
        top: 0;
        max-width: 60%;
        height: auto;
        z-index: -1;
    }
}
.inr-left-gr{
    position: absolute;
    height: 100%;
    top:0;
    width: 7px;
    left: -20px;
    background: #02fff6;
    background: -moz-linear-gradient(top,  #02fff6 0%, #ffff00 25%, #ff008a 50%, #ffff00 75%, #02fff6 100%);
    background: -webkit-linear-gradient(top,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
    background: linear-gradient(to bottom,  #02fff6 0%,#ffff00 25%,#ff008a 50%,#ffff00 75%,#02fff6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02fff6', endColorstr='#02fff6',GradientType=0 );
    animation-name: grtopbottom;
    background-size:auto 200%;
    animation-duration: 3s; 
    animation-timing-function: linear; 
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;     
   }
   .inr-pink-block{
    background-color: #ff005a;
    border-radius: 16px;
    box-shadow: 38px 0px 152px rgba(255, 0, 90, 0.45);
    color: $white-color;
    font-weight: 800;
    font-size: 26px;
    display: table;
    max-width: 280px;
    margin: 0px auto;
    padding: 25px;
    line-height: 1;
   }