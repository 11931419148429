body {
  @include body-font;
  padding-top:0px;
  overflow-x:hidden;
  overflow-y:scroll;
  //cursor:none;
 /* &:not(.tool-page) {
    background:$white-color url(../img/noise-background.jpg) repeat;
  }
  &.no-background {
    background-image:none;
  }*/
  &.default-cursor {
    cursor:default;
    .cursor,
    .cursor-follower {
      display:none;
    }
  }
  &.menuopen{
    overflow: hidden;
  }
  /**** white mode ****/
  
    background:$black-color url(../img/light-noise-background.jpg) repeat;
    color:$black-color;
    .btn{color:$black-color;}  
    a{
      color:$black-color;
    }
    .get-touch a{
      color:$white-color;
    }
    
    .border-text, .inner-page .border-tex{
      -webkit-text-stroke-color:$black-color;
    }    
    .header, .navbar-brand, .contact-sec .contat-block a, .contact-sec .contact-block button[type="submit"], .ads-banner img, .fixed-top.menuopen, .fixed-top.menuopen .marquee, .magento-sec1 .magento-rounds, .mage-payment img:not(.mage-pay-hidden), .sound-buttons{
      filter:invert(1);
    }
    .form-group .form-control {
      border:1px solid $black-color;
    }
    &:not(.menuopen) .fixed-top {
      @include media-breakpoint-down(md) {
        background:$white-color;
        border-bottom:1px solid #eaeaeadd;
      }
    }   
    .marquee a span {
      color:$white;
    }
    .cursor-follower, .software-dev .soft-round, .contact-sec .contact-block a, .input-group-text{
      border-color:$black-color;
    }
    .modal-backdrop, .ads-banner{
      background-color:$white-color;
    }
    .home-sec2 .payment-logos{
      border-color:$white-color;
    }
    .software-dev .soft-border, .software-dev .polygons .polygon, .software-dev .triangles .triangle{
      filter:brightness(0);
    }
    &.ashade-layout--vertical .ashade-back-wrap:before, .ashade-page-title-wrap:before{
      background:#e1e1e1;
    }
    .cls-1, .cls-2, .cls-5{stroke:#a4c41c;}
    .ppc-h2 > span > span{mix-blend-mode:normal;}  
    &.blog .nav-links .page-numbers{border-color:$black-color;color:$black-color;}
    .cursor-follower.active{background-color:transparent}
    .and-new2, .and-new4{background-color:$black-color;}
    .and-new1 > img[alt="Dots"],
    .and-new3{
      .and-corner1, .and-corner2{
        filter:invert(1);
      }
    }
    .and-new4{
      &::after{
        filter:invert(1);
      }
    }    
    .and-new4 img[alt="Dots"]{filter:none;}
    .and-list li{
      border-color:$black-color;
      &:before{border-color:$black-color;}
    }
    .marquee-container{
      background-color: $black-color;
    }
    @include media-breakpoint-down(md){   
      header{filter: invert(1);} 
      .navbar-toggler, .navbar-brand {
        filter:none;
      }
      .nav-link{
        color: $black-color;
      }    
    }    
  }

html,
body {
  height:100%;
  scroll-behavior:smooth;
}
body:not(.white-mode) .tooltip {
  &.bs-tooltip-left {
    .arrow:before {
      border-left-color:$black-color;
    }
  }
  &.bs-tooltip-top {
    .arrow:before {
      border-top-color:$black-color;
    }
  }
  .tooltip-inner {
    background-color:$black-color;
    color:$white-color;
  }
}
.global-noise {
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  pointer-events:none;
  z-index:-1;
  opacity: 0.05;
  canvas {
    height:100%;
    width:100%;
  }
}
.page-wrapper {
  display:block;
  overflow:hidden;
  width:100%;
}
main {
  min-height:670px;
  padding:0px;
  width:100%;
  @include media-breakpoint-down(md) {
    min-height:350px;
  }
}
.container {
  @include media-breakpoint-up(xl) {
    max-width:75%;
  }
  @include media-breakpoint-down(xs) {
    width:calc(100% - 30px);
  }
}
section {
  width:100%;
  padding:70px 0px;
  @include media-breakpoint-down(md) {
    padding:50px 0px;
  }
}
img {
  display:inline-block;
  max-width:100%;
}
.divImg {
  @include bg-cmn;
}
a {
  &:hover {
    text-decoration:none;
  }
  &:hover,
  &:focus,
  &:active {
    outline:none;
    border:none;
  }
}
/*** Heading ***/
h1, .h1 {@include font-xl;}
h2, .h2 {@include font-lg;}
h3, .h3 {@include font-md;}
h4, .h4 {@include font-sm;}
h5, .h5 {@include font-xs;}
h6, .h6 {@include font-xxs;}
/*** Listing ***/
ul {
  margin:0px 0px 30px;
  padding:0;
  li {
    list-style:none;
    @include body-font;
    margin:0px 0px 10px;
    padding:0px 0px 0px 28px;
    position:relative;
    &:before {
      content:"";
      width:14px;
      height:14px;
      position:absolute;
      left:0px;
      top:14px;
      background:#ff9800;
      @include border-radius(100%);
    }
  }
  &.ygreen-list {
    li {
      &:before {
        background:#d5ff20;
      }
    }
  }
  &.green-list {
    li {
      &:before {
        background:#85ff90;
      }
    }
  }
  &.red-list {
    li {
      &:before {
        background:#b30246;
      }
    }
  }
}
ul.list-unstyled {
  li {
    margin:0px;
    padding:0px;
    &:before {
      content:normal;
    }
  }
}
ol {
  margin:0px 0px 30px;
  padding:0px;
  list-style:none;
  li {
    counter-increment:av-counter;
    @include body-font;
    margin:0px 0px 20px;
    padding:0px 0px 0px 28px;
    position:relative;
    &:before {
      content:counter(av-counter);
      counter-increment:none;
      color:$primary-color;
      position:absolute;
      left:0px;
      top:0px;
    }
  }
}
/****Buttons***/
button {
  &:focus {
    outline:none;
  }
}
p {
  margin-bottom:40px;
}
.btn {
  text-transform:capitalize;
  font-size:18px;
  padding:15px;
  position:relative;
  min-width:150px;
  line-height:1;
  @include transition(0.8s);
  @include border-radius(0);
  @include Montserrat-font;
  border:2px solid;
  &.btn-primary {
    background-color:transparent;
    color:$black-color;
    border-color:$black-color;
    &:not(:disabled):not(.disabled):active,
    &:focus,
    &:hover {
      color:$white-color;
      background-color:$black-color;
      box-shadow:none;
      border:2px solid $black-color;
    }
  }
  &.btn-info {
    background-color:#00a7fc;
  }
  &.btn-sm {
    padding:5px;
    min-width:100px;
    display:table;
  }
}
/*** Form Element ***/
.form-group {
  margin-bottom:10px;
  .form-control {
    border-radius:0px;
    height:29px;
    color:#070707;
    @include placeholder {
      color:#585858;
    }
  }
  textarea.form-control {
    height:auto;
  }
}
.form-control {
  @include Montserrat-font;
}
.invalid-feedback {
  font-size:14px;
}
.rainbow-animation {
  background:rgb(214, 51, 219);
  background:-moz-linear-gradient(
    left,
    rgb(214, 51, 219) 0%,
    rgb(89, 39, 217) 7%,
    rgb(39, 39, 217) 11%,
    rgb(39, 60, 217) 13%,
    rgb(39, 170, 217) 20%,
    rgb(39, 214, 217) 23%,
    rgb(39, 217, 205) 25%,
    rgb(39, 217, 176) 27%,
    rgb(39, 217, 66) 34%,
    rgb(39, 217, 39) 37%,
    rgb(66, 217, 39) 39%,
    rgb(170, 217, 39) 46%,
    rgb(205, 217, 39) 48%,
    rgb(217, 217, 39) 50%,
    rgb(217, 211, 39) 50%,
    rgb(217, 217, 39) 50%,
    rgb(205, 217, 39) 52%,
    rgb(170, 217, 39) 54%,
    rgb(66, 217, 39) 61%,
    rgb(39, 217, 39) 63%,
    rgb(39, 217, 80) 67%,
    rgb(39, 217, 181) 73%,
    rgb(39, 214, 217) 77%,
    rgb(39, 170, 217) 80%,
    rgb(39, 60, 217) 87%,
    rgb(39, 39, 217) 89%,
    rgb(89, 39, 217) 93%,
    rgb(214, 51, 219) 100%
  );
  background:-webkit-linear-gradient(
    left,
    rgb(214, 51, 219) 0%,
    rgb(89, 39, 217) 7%,
    rgb(39, 39, 217) 11%,
    rgb(39, 60, 217) 13%,
    rgb(39, 170, 217) 20%,
    rgb(39, 214, 217) 23%,
    rgb(39, 217, 205) 25%,
    rgb(39, 217, 176) 27%,
    rgb(39, 217, 66) 34%,
    rgb(39, 217, 39) 37%,
    rgb(66, 217, 39) 39%,
    rgb(170, 217, 39) 46%,
    rgb(205, 217, 39) 48%,
    rgb(217, 217, 39) 50%,
    rgb(217, 211, 39) 50%,
    rgb(217, 217, 39) 50%,
    rgb(205, 217, 39) 52%,
    rgb(170, 217, 39) 54%,
    rgb(66, 217, 39) 61%,
    rgb(39, 217, 39) 63%,
    rgb(39, 217, 80) 67%,
    rgb(39, 217, 181) 73%,
    rgb(39, 214, 217) 77%,
    rgb(39, 170, 217) 80%,
    rgb(39, 60, 217) 87%,
    rgb(39, 39, 217) 89%,
    rgb(89, 39, 217) 93%,
    rgb(214, 51, 219) 100%
  );
  background:linear-gradient(
    to right,
    rgb(214, 51, 219) 0%,
    rgb(89, 39, 217) 7%,
    rgb(39, 39, 217) 11%,
    rgb(39, 60, 217) 13%,
    rgb(39, 170, 217) 20%,
    rgb(39, 214, 217) 23%,
    rgb(39, 217, 205) 25%,
    rgb(39, 217, 176) 27%,
    rgb(39, 217, 66) 34%,
    rgb(39, 217, 39) 37%,
    rgb(66, 217, 39) 39%,
    rgb(170, 217, 39) 46%,
    rgb(205, 217, 39) 48%,
    rgb(217, 217, 39) 50%,
    rgb(217, 211, 39) 50%,
    rgb(217, 217, 39) 50%,
    rgb(205, 217, 39) 52%,
    rgb(170, 217, 39) 54%,
    rgb(66, 217, 39) 61%,
    rgb(39, 217, 39) 63%,
    rgb(39, 217, 80) 67%,
    rgb(39, 217, 181) 73%,
    rgb(39, 214, 217) 77%,
    rgb(39, 170, 217) 80%,
    rgb(39, 60, 217) 87%,
    rgb(39, 39, 217) 89%,
    rgb(89, 39, 217) 93%,
    rgb(214, 51, 219) 100%
  );
  filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#d633db', endColorstr='#d633db',GradientType=1 );
  animation-name:stretch;
  background-size:200% auto;
  animation-duration:3s;
  animation-timing-function:linear;
  animation-direction:normal;
  animation-iteration-count:infinite;
  animation-fill-mode:none;
  animation-play-state:running;
}
@include keyframes(stretch) {
  0% {
    background-position:200% center;
  }
  100% {
    background-position:0% center;
  }
}
// Banner
.cmn-banner {
  background-size:cover;
  background-position:center center;
  min-height:350px;
  text-align:center;
  align-items:center;
}
// M Custom Scrollbar
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width:5px;
  border:none;
  background-color:$white-color;
  border-radius:0px;
}
.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  @include opacity(1);
}
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color:$white-color;
}
.rellax-element {
  .rellax {
    background-color:$white-color;
    box-shadow:6px 12px 20px 8px black;
  }
}
.cursor {
  position:fixed;
  background-color:#fff;
  width:6px;
  height:6px;
  border-radius:100%;
  z-index:1;
  transition:0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  user-select:none;
  pointer-events:none;
  z-index:999999999;
  transform:scale(1);
  &.active {
    @include opacity(0.5);
    transform:scale(0);
  }
  &.hovered {
    @include opacity(0.08);
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}
.cursor-follower {
  position:fixed;
  width:33px;
  height:33px;
  border-radius:100%;
  background-color:transparent;
  border:3px solid #fff;
  z-index:1;
  transition:0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  user-select:none;
  pointer-events:none;
  z-index:999999999;
  transform:translate(5px, 5px);
  mix-blend-mode:exclusion;
  &.active {
    transform:scale(3);
    background-color:#fff;
  }
  &.hovered {
    background-color:#fff;
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}
/****Page left Right Shadow ***/
main {
  position:relative;
  .page-left-shadow,
  .page-right-shadow {
    position:absolute;
    z-index:-1;
    -webkit-animation:huerotate 4s infinite;
    animation:huerotate 4s infinite;
    top:-30px;
    width:8%;
    height:auto;
    @include media-breakpoint-down(sm) {
      width:18%;
    }
  }
  .page-left-shadow {
    left:0;
  }
  .page-right-shadow {
    right:0;
  }
}
@include keyframes(huerotate) {
  0% {
    -webkit-filter:hue-rotate(360deg);
  }
  100% {
    -webkit-filter:hue-rotate(0deg);
  }
}
.chosen-container {
  @include Montserrat-font;
  .chosen-results {
    li {
      @include Montserrat-font;
      font-size:16px;
      &::before {
        display:none;
      }
      &.result-selected {
        &::before {
          background:none;
        }
      }
      &.highlighted {
        background-color:$black-color;
      }
    }
  }
  .chosen-single {
    height:40px;
    border-radius:7px;
    border-color:#b9b9b9;
    color:$black-color;
  }
  &.chosen-container-active {
    .chosen-single {
      border-color:#b9b9b9;
    }
  }
  .chosen-drop {
    border-color:#b9b9b9;
  }
}
.loader {
  display:none;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  z-index:10000;
  background:rgba(0, 0, 0, 0.85);
  .loader-g {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    -ms-transform:translate(-50%, -50%);
  }
}
/*body{overflow:hidden;}
.page-wrapper{height:100%;}
.scroll-ontainer {position:static !important;}*/
/*.viewport{overflow:hidden;position:fixed;height:100%;width:100%;top:0;left:0;right:0;bottom:0;}
.page-wrapper{position:absolute;overflow:hidden;z-index:10;backface-visibility:hidden;transform-style:preserve-3d;width:100%;}*/
:root {
  --loaderEffact:hover1 1s linear infinite;
}
#preloader {
  position:fixed;
  display:flex;
  width:100vw;
  height:100vh;
  background:$white-color;
  align-items:center;
  justify-content:center;
  z-index:99999999999;
  .pageloader {
    display:block;
    p {
      display:inline-block;
      text-transform:uppercase;
      text-align:center;
      font-size:2.6em;
      font-weight:600;
      transform:scale(.5);
      color:#121212;
      margin-bottom:0px;
      -webkit-text-stroke:2px gray;
      &:nth-child(1){-webkit-animation:var(--loaderEffact);animation:var(--loaderEffact);}    
      &:nth-child(2){-webkit-animation:var(--loaderEffact) .125s;animation:var(--loaderEffact) .125s;}
      &:nth-child(3){-webkit-animation:var(--loaderEffact) .25s;animation:var(--loaderEffact) .25s;}
      &:nth-child(4){-webkit-animation:var(--loaderEffact) .375s;animation:var(--loaderEffact) .375s;}
      &:nth-child(5){-webkit-animation:var(--loaderEffact) .5s;animation:var(--loaderEffact) .5s;}
      &:nth-child(6){-webkit-animation:var(--loaderEffact) .675s;animation:var(--loaderEffact) .675s;}
      &:nth-child(7){-webkit-animation:var(--loaderEffact) .75s;animation:var(--loaderEffact) .75s;}
    }  
  }  
}
@keyframes hover1 {
  0% {
    transform:scale(.5);
    color:#121212;
    -webkit-text-stroke:2px gray;
  }
  20% {
    transform:scale(1);
    color:pink;
    -webkit-text-stroke:3px red;
    filter:drop-shadow(0 0 1px black)drop-shadow(0 0 1px black)drop-shadow(0 0 3px red)drop-shadow(0 0 5px red)hue-rotate(10turn);
  }
  50% {
    transform:scale(.5);
    color:#121212;
    -webkit-text-stroke:2px gray;
  }
}
@-webkit-keyframes hover1 {
  0% {
    -webkit-transform:scale(.5);
    color:#121212;
    -webkit-text-stroke:2px gray;
  }
  20% {
    -webkit-transform:scale(1);
    color:pink;
    -webkit-text-stroke:3px red;
    filter:drop-shadow(0 0 1px black)drop-shadow(0 0 1px black)drop-shadow(0 0 3px red)drop-shadow(0 0 5px red)hue-rotate(10turn);
  }
  50% {
    -webkit-transform:scale(.5);
    color:#121212;
    -webkit-text-stroke:2px gray;
  }
}

p.form-submit{clear:both;}