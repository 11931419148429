/*@font-face {
  font-family: 'perfect_dos_vga_437_winRg';
  font-display: swap;  
  src: url('../fonts/perfect_dos_vga_437_win-webfont.eot');
  src: url('../fonts/perfect_dos_vga_437_win-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/perfect_dos_vga_437_win-webfont.woff2') format('woff2'),
       url('../fonts/perfect_dos_vga_437_win-webfont.woff') format('woff'),
       url('../fonts/perfect_dos_vga_437_win-webfont.svg#perfect_dos_vga_437_winRg') format('svg');
  font-weight: normal;
  font-style: normal;   
}      
@font-face { 
  font-family: 'monument-extended';
  font-weight: normal;
  font-style: normal; 
  font-display: swap;   
  src: url('../fonts/monument-extended-webfont.woff2') format('woff2'),
       url('../fonts/monument-extended-webfont.woff') format('woff');  
} 
@font-face {
  font-family: 'Stalinist One';
  font-weight: normal;
  font-style: normal; 
  font-display: swap; 
  src: url('../fonts/stalinistone-regular-webfont.woff2') format('woff2'),
       url('../fonts/stalinistone-regular-webfont.woff') format('woff');      
}
@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat-black-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap; 
}
@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap; 
}
@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat-medium-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap; 
}
@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap; 
}
@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat-thin-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap; 
}
@font-face {
  font-family: 'poppins';
  src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
       url('../fonts/poppins-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'poppins';
  src: url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
       url('../fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'poppins';
  src: url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
       url('../fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
} */
@font-face { 
  font-family: 'monument-extended';
  font-weight: normal;
  font-style: normal; 
  font-display: swap;   
  src: url(../fonts/monument-extended-webfont.woff2) format('woff2'),
       url(../fonts/monument-extended-webfont.woff) format('woff');  
}
/*** Font Family  ***/
$font-body:halyard-display, sans-serif;
$perfect-dos: 'perfect_dos_vga_437_winRg';
$stalinist:'Stalinist One', cursive;
$montserrat: 'Montserrat', sans-serif;
$monument-ext: 'monument-extended';
$poppins:'poppins';
/*** Color Palette ***/
$primary-color: #4CB964; 
$secondary-color: #2E4057;
$white-color: #ffffff;
@mixin body-font { 
font-family: $font-body;
  @include fluid-type($min_width, $max_width, 20px, 28px); 
  font-weight:400;  
  line-height: 1.4; 
  letter-spacing: 2px;
}
@mixin Montserrat-font { 
    font-family: $montserrat;
    letter-spacing: 0px;
  }
/*** Font-size as h1-h5 headings ***/
@mixin font-xl { font-size: 44px; line-height: 50px; font-weight: 500;@include Montserrat-font; }
@mixin font-lg { @include fluid-type($min_width, $max_width, 37px, 85px); line-height: 0.99;font-weight: 900;@include Montserrat-font; }
@mixin font-md { font-size: 28px; line-height: 36px; font-weight: 500;@include Montserrat-font; }
@mixin font-sm { @include fluid-type($min_width, $max_width, 26px, 38px);@include Montserrat-font; }
@mixin font-xs { font-size: 18px; font-weight: 700;@include Montserrat-font; }
@mixin font-xxs { font-size: 14px; font-weight: 500;@include Montserrat-font; }
@mixin border-radius($radius) { -webkit-border-radius: $radius; -moz-border-radius: $radius; -o-border-radius: $radius;	border-radius: $radius; }
@mixin opacity($opacity) { -webkit-opacity:$opacity; -moz-opacity:$opacity; -o-opacity:$opacity; -m-opacity:$opacity; opacity:$opacity; }
@mixin transition($seconds) { -webkit-transition: all $seconds ease; -moz-transition: all $seconds ease; -ms-transition: all $seconds ease; -o-transition: all $seconds ease; transition: all $seconds ease; }
@mixin trans-anim-none { -webkit-transition:none; -moz-transition:none; -o-transition:none; transition:none; }
@mixin space-none { margin:0; padding:0; }
@mixin app-none { -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; -ms-appearance: none; appearance: none;}
@mixin point-none { -webkit-pointer-events:none; -moz-pointer-events:none; -o-pointer-events:none; -ms-pointer-events:none; pointer-events:none; }
/*** Background properties  ***/
@mixin bg-cmn { background-position:top center; background-repeat:no-repeat; background-size: cover; }
@mixin bg-parallax { background-attachment:fixed; @include bg-cmn; }