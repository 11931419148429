footer {
  font-size:16px;
    @include media-breakpoint-down(lg) {
    font-size:14px;
  }
  ul {
    li {
      font-size:16px;
      @include media-breakpoint-down(lg) {
        font-size:14px;
      }
      @include Montserrat-font;
      margin-bottom:9px !important;
      a {
        color:$black-color ;
        letter-spacing:0px;
        line-height:1.3;
        display:inline-block;
        &:hover {
          color:#f14f53;
          text-decoration:none;
        }
      }
    }
  }
}
.footer-address {
  line-height:1.5;
  span {
    //color:#77ffb4;
    display:block;
    font-weight:500;
  }
  .footer-add-block {
    margin-bottom:10px;
    img{
      width:30px;
      display:inline-block;
      box-shadow:0px 0px 5px #606060;
      vertical-align: -2px;
    }   
  }
}
.footer-categories{
  border-bottom: 1px solid #454545;
  strong{
    //color:$red;
    display:block;
    font-weight:500;
    letter-spacing: -1px;
    margin-bottom:10px;
    font-family:$montserrat;
    border-bottom: 1px solid #cfcfcf;
  }
}
.contact-sec {
  .container {
    background:url(../../img/cotact-grid.png) no-repeat 0 0;
    background-size:100% 100px;
    background-position:bottom center;
    padding-bottom:110px;
    @media only screen and (max-width:1815px) {
      background-size:100% 70px;
    }
    @include media-breakpoint-down(md) {
      background-size:100% 45px;
      padding-bottom:28px;
    }
  }
  .contact-block {
    .contact-support {
      display:flex;
      justify-content:space-between;
      @include media-breakpoint-down(sm) {
        margin-bottom:15px;
        display:block;
      }
      li {
        display:inline-block;
        vertical-align:top;
        margin-bottom:15px;
        font-size:17px;
        @include Montserrat-font;
        p {
          margin-bottom:10px;
        }
      }
    }
    h5 {
      margin-bottom:30px;
      font-weight:100;
      @include fluid-type($min_width, $max_width, 30px, 50px);
      @include media-breakpoint-down(sm) {
        margin-bottom:15px;
      }
    }
    p {
      letter-spacing:3px;
      line-height:1.3;
      @include media-breakpoint-down(sm) {
        margin-bottom:15px;
      }
    }
    a {
      border:1px solid #fff;
      border-radius:3px;
      display:inline-block;
      font-weight:400;
      padding:2px 12px;
      @include transition(0.4s);
      &:hover {
        text-decoration:none;
        background:#ddd;
        color:$black-color;
      }
    }
    button[type="submit"] {
      background:transparent;
      cursor:url(../../img/cursor-emoji.png), auto;
      @include app-none;
      font-weight:bold;
      text-transform:uppercase;
      color:#fff;
      border:1px solid #fff;
      border-radius:4px;
      padding:2px 12px;
      float:right;
      @include Montserrat-font;
      &:hover {
        color:#f14f53;
      }
    }
    .appfutura-logo{
      h6{font-size: 15px;
        text-transform: uppercase;
        font-weight: 600;}
    }
  }
  .contact-lable {
    transform:rotate(-5deg);
    display:table;
    margin:0px auto 40px;
    font-size:18px;
    @include media-breakpoint-down(md) {
      margin-top:22px;
    }
  }
}
.modal-backdrop.show {
  @include opacity(0.9);
}
.success-modal {
  .modal-content {
    font-family:$perfect-dos;
    color:$black-color;
    border-radius:0px;
    background:#a8a8a8;
    box-shadow:15px 12px 0px 2px $black-color;
    .modal-header {
      background:#fcfcfc;
      padding:8px 20px 3px;
      font-size:24px;
    }
    .modal-body {
      padding-bottom:40px;
      font-family:$perfect-dos;
      h4 {
        font-size:50px;
        margin-bottom:18px;
        font-family:$perfect-dos;
        span {
          background-color:#4ff4f2;
          color:#fff600;
          padding:0px 15px;
        }
      }
      h5 {
        font-family:$perfect-dos;
        font-weight:normal;
        font-size:30px;
        span {
          color:#5ef935;
        }
      }
      .btn {
        font-family:$perfect-dos;
        margin-top:15px;
        border-radius:0px;
        padding:6px 0 3px;
        font-size:32px;
        background-color:#fcfcfc;
        min-width:200px;
        box-shadow:15px 12px 0px 2px $black-color;
        color:$black-color;
        &:before {
          display:none;
        }
      }
    }
  }
  &.neoboard-modal {
    letter-spacing:0.5px;
    border:none;
    .koshertop-modal {
      .modal-header {
        background-color:#f5f5f5;
        color:$black-color;
        border-color:#dfdfdf;
      }
      .modal-body {
        background-color:$white-color;
        color:$black-color;
        h5,
        h4 {
          color:$black-color;
        }
        span{
          color:#5da201;
        }
      }
      #flashOffer {
        background:#5da201;
        color:#fec20c;
      }
      .btn {
        background:#5da201;
        color:$white-color;
      }
    }
    .neob-modal {
      .modal-header {
        background:#2a2b52;
        color:$white-color;
        border-color:#2a2b52;
      }
      .modal-body {
        background-color:#38395b;
        color:$white-color;
        h5,
        h4 {
          color:$white-color;
        }
      }
      #flashOffer {
        background:#00c4ff;
        color:$white-color;
      }
      .btn {
        background:linear-gradient(90deg, #00c4ff 0, #0075ff 100%);
        color:$white-color;
      }
    }
    .ram-modal {
      .modal-header {
        background-color:$black-color;
        color:$white-color;
        border-color:$black-color;
      }
      .modal-body {
        background-color:$white-color;
        color:$black-color;
        h5,
        h4 {
          color:$black-color;
        }
      }
      #flashOffer {
        background:#f2ff00;
        color:$black-color;
      }
      .btn {
        background:#f2ff00;
        color:$black-color;
      }
    }
    .athletis-modal {
      .modal-header {
        background-color:$black-color;
        color:$white-color;
        border-color:$black-color;
      }
      .modal-body {
        background-color:$white-color;
        color:$black-color;
        h5,
        h4 {
          color:$black-color;
        }
      }
      #flashOffer {
        background:#3b90d1;
        color:$white-color;
      }
      .btn {
        background:#3b90d1;
        color:$white-color;
      }
    }
    @include media-breakpoint-down(md) {
      .modal-header {
        font-size:13px;
        padding:0px 0px 0px 12px !important;
        align-items:center;
        #js-close-button {
          display:inline-block;
          width:40px;
          height:30px;
          line-height:30px;
          text-align:center;
        }
      }
      .modal-body {
        h4 {
          font-size:22px;
        }
        h5 {
          font-size:15px;
          line-height:1.15;
        }
        .btn {
          font-size:18px;
        }
      }
    }
  }
}
.input-group {
  .input-group-text {
    color:$black-color;
  }
  .form-control {
    &.no-valid {
      border-color:#dc3545;
      padding-right:calc(1.5em + 0.75rem);
      background-image:url(../../img/input-invalid.svg);
      background-repeat:no-repeat;
      background-position:right calc(0.375em + 0.1875rem) center;
      background-size:calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    &.valid {
      border-color:#28a745;
      padding-right:calc(1.5em + 0.75rem);
      background-image:url(../../img/input-valid.svg);
      background-repeat:no-repeat;
      background-position:right calc(0.375em + 0.1875rem) center;
      background-size:calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
}
.footer-bottom {
  display:flex;
  justify-content:space-between;
  @include media-breakpoint-down(sm) {
    display:block;
    text-align: center;
  }
  .footer-copy {
    font-size:13px;
    line-height:1.6;
    @include media-breakpoint-down(md){
      margin-bottom:16px;
    }
  }
  .footer-social {
    @include media-breakpoint-down(md){
      text-align: center;
      margin-bottom: 75px;
    }
    li {
      display:inline-block;
      margin-left:10px;
      font-size:24px;
      @include media-breakpoint-down(sm) {
        margin-left:0px;
        margin-right:10px;
      }
      a {
        color:#c5c5c5;
        &:hover {
          color:#f5b239;
        }
        i {
          font-style:normal;
        }
      }
    }
  }
}
.modal-holder {
  position:absolute;
  width:30rem;
  height:28.125rem;
  z-index:9999;
  top:50%;
  left:50%;
  margin-left:-15rem;
  margin-top:-14.0625rem;
  display:none;
  .svg-overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  .break-parts {
    width:100%;
    height:100%;
    opacity:0;
    z-index:0;
    display: none;
  }
  .effect {
    position:absolute;
    top:-7.625rem;
    left:0.125rem;
    z-index:4;
    display:none;
    @include media-breakpoint-down(md) {
      left:-6.125rem;
    }
  }
}
.effect__burst {
  position:absolute;
  width:3.625rem;
  height:3.625rem;
  left:20.125rem;
  top:1.4375rem;
  opacity:0.5;
}
.effect__circle {
  position:absolute;
  left:25rem;
  top:6.25rem;
  width:6.25rem;
  height:6.25rem;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
}
.effect__line {
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  position:absolute;
  height:56.25rem;
  width:0.25rem;
  left:28.0625rem;
  top:9.375rem;
  transform-origin:50% 0;
}
.effect__line--1 {
  transform:rotate(45deg);
}
.effect__line--2 {
  transform:rotate(-45deg);
}
.effect__line--3 {
  transform:rotate(-135deg);
}
.effect__line--4 {
  transform:rotate(-225deg);
}
.svg-overlay {
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  display:none;
  svg {
    width:100%;
    height:100%;
    top:0;
    left:0;
    position:absolute;
  }
}
.svg-overlay--2 {
  transform-origin:96% 2%;
}
.svg-overlay--3 {
  transform-origin:97% 6%;
}
.hpost-titles{  
  .hpost-title {
    position:fixed;
    left:-100%;
    max-width:151px;
    z-index:99;
    background-color:$black-color;
   /*@include linear-gradient(to right, #e2a9a9 0%, #e8d6ff 100%);*/
   /*-webkit-animation:huerotate 4s infinite;
    animation:huerotate 4s infinite;*/
    border-left:none;
    bottom:80px;
    @include transition(2s);
    padding:0px 8px 3px;
    font-size:12px;
    font-weight:400;
    border-radius:0px 6px 6px 0px;
    a {
      color:$white-color;
    }
    &.hpost-title-show {
      left:0px;
    }
    @include media-breakpoint-down(md){
      left:-240px;
      max-width:240px;
      letter-spacing: 0.5px;
      font-size: 13px;
      bottom:20px;
      /*position:static;
      display:inline-block;
      border-radius:0px;
      max-width:none;
      background-color: none;*/
    }
  }
  /*@include media-breakpoint-down(md){   
    animation:marquee 200s linear infinite;
    white-space:nowrap;
    will-change:transform;
    position:fixed;
    bottom:4px;
  }*/
  .slick-track{
    display: flex !important;
  }
  .slick-slide{
    height: inherit !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.seo-promotion {
  position:fixed;
  width:210px;
  right:-225px;
  top:120px;
  border:3px solid #474747;
  border-right:0px;
  z-index:99;
  @include transition(2s);
  cursor:pointer;
  .seo-promo-close {
    height:26px;
    width:26px;
    margin-left:-12px;
    margin-top:-12px;
    border-radius:1000px;
    border:2px solid #ddd;
    font-size:14px;
    background:#b60000;
    color:white;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
  }
}
.quick-contact-btn {
  position:fixed;
  bottom:30px;
  background-color:$black-color;
  left:3px;
  border-radius:100px;
  font-size:18px;
  overflow:hidden;
  @include media-breakpoint-down(md){
    font-size:14px;
    position: relative;
    display: none;
    margin-bottom: 10px;
    bottom: auto;
  }
  span {
    background-color:rgba(0, 0, 0, 0.5);
    padding:5px;
    display:inline-block;
    vertical-align:-2px;
    border-radius:0px 8px 8px 0px;
    border-left: 1px solid #454545;
    img {     
      width:24px;
      @include media-breakpoint-down(md){
        width:20px;
      }
      vertical-align:-2px;
      display:inline-block;
    }
  }
  /*&::before {
    content:"";
    background:linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position:absolute;
    top:-2px;
    left:-2px;
    background-size:400%;
    z-index:-1;
    filter:blur(5px);
    width:calc(100% + 4px);
    height:calc(100% + 4px);
    animation:glowing 20s linear infinite;
    transition:opacity 0.3s ease-in-out;
    border-radius:0px 8px 8px 0px;
  }
  &::after {
    z-index:-1;
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.65);
    left:0;
    top:0;
    border-radius:0px 8px 8px 0px;
  }*/
}
@keyframes glowing {
  0% {
    background-position:0 0;
  }
  50% {
    background-position:400% 0;
  }
  100% {
    background-position:0 0;
  }
}
@keyframes marquee {
  from { transform:translateX(0); }
  to { transform:translateX(-100%); }
}
/*
.switch-panel {
  position:fixed;
  bottom:120px;
  right:0;
  letter-spacing:1px;
  user-select:none;
  background-color:#404040;
  width:34px;
  box-shadow:0 0 6px rgba(255, 255, 255, 0.25);
  z-index:6;
  > div {
    padding:5px;
    height:34px;
    @include transition(0.5s);
    &:hover {
      background-color:#5d5d5d;
    }
    img {
      vertical-align:top;
    }
    &:not(:last-child) {
      border-bottom:1px solid #636363;
    }
  }
} */
.switch-panel {
  position:fixed;
  bottom:120px;
  right:0;
  user-select:none;
  z-index:6;
  //background-color:#333333;
  padding:6px 0px;
  label{
    font-size:14px;
    letter-spacing:1px;
    margin-bottom:0;
    text-indent:8px;
  }
  input[type="checkbox"] {
    margin:6px 6px 0px;
    position:relative;
    width:60px;
    height:20px;
    -webkit-appearance:none;
    @include linear-gradient(0deg, #333, #000);
    outline:none;
    border-radius:20px;
    box-shadow:0 0 0 4px #353535, 0 0 0 5px #3e3e3e, inset 0 0 10px rgba(0, 0, 0, 1);    
    &:before {
      content:"";
      position:absolute;
      top:0;
      left:0;
      width:40px;
      height:20px;
      @include linear-gradient(0deg, #000, #6b6b6b);
      border-radius:20px;
      box-shadow:0 0 0 1px #232323;
      transform:scale(0.98, 0.96);
      transition:0.5s;
    }
    &:after {
      content:"";
      position:absolute;
      top:calc(50% - 2px);
      left:30px;
      width:4px;
      height:4px;
      @include linear-gradient(0deg, #6b6b6b, #000);
      border-radius:50%;
      transition:0.5s;
    }
    &:checked[type="checkbox"]{
      @include linear-gradient(0deg, #e62222, #f31212);
      box-shadow:0 0 0 4px #353535, 0 0 0 5px #3e3e3e, inset 0 0 10px rgba(0, 0, 0, 1);
      &:before {
        left:20px;
      }
      &::after {
        left:50px;
      }      
    }  
    @include media-breakpoint-down(md){  
      &.check-cursor{      
        display:none;
      }
      &.check-mode{
        transform:rotate(90deg);
        transform-origin:left 60px;
        margin-bottom:10px;
      }
    }    
  }
}

.extension-browser .toast{
  max-width: 350px;
  background-color: #000;  
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #00bb39;
  box-shadow: 0 0.25rem 0.75rem #ddd;
  border-radius: 0.25rem;
  z-index: 999999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: auto;
  h5{
    color: #fff;
  }
}

.contact-sec .profiles-logos{
  a{
    height: calc(100% - 42px);
    display: flex ;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    @include media-breakpoint-down(xs){
      height: auto;
      width: 230px;
      margin: 0px auto 20px;
    }    
  }
  @include media-breakpoint-up(sm){
    h6{min-height:34px;}
  }
  @include media-breakpoint-down(sm){
    h6 br{display:none;}
  }
}

/**** Back to top *****/
.progress-wrap {
	position: fixed;
	right: 50px;
	bottom: 40px;
	height: 40px;
	width: 40px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset  0 0 0 2px rgba(0, 0, 0, 0.2);
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	-webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  @include media-breakpoint-down(md){
    right: 30px;
	  bottom: 20px;
  }
  &.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }  
  svg path { 
    fill: none; 
  }
  svg.progress-circle path {
    stroke:$black;
    stroke-width: 4;
    box-sizing:border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  &::before{
    position: absolute;
    text-align: center;
    line-height: 40px;
    font-size:16px;
    left: 0;
    top: 0;
    height: 40px;
    width: 42px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
}