.java-left-shadow, .java-right-shadow{
    position: absolute;        
    z-index: -1;       
    top: -30px;
    width: 8%;
    height: auto;
    @include media-breakpoint-down(sm){
        width: 18%;
    }
}
.java-left-shadow{
    left: 0;
}
.java-right-shadow{
    right: 0;
}
.inr-pink-blk-100{
    max-width: 100%;
    font-size: 42px;
}