.navbar-brand {
  svg,
  img {
    height: 26px;
    width: 140px;
  }
}
.navbar-toggler {
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center right;
  color: #ffff;
  text-transform: uppercase;
  @include Montserrat-font;
  font-weight: 600;
  font-size: 24px;
  padding-right: 38px;
  background-image: url(../../img/hamburger-close.svg);
  &[aria-expanded="false"] {
    background-image: url(../../img/hamburger.png);
    background-size: 30px;
  }
  &[aria-expanded="true"] {
    filter: invert(1);
    position: relative;
    z-index: 9999;
    transition: all 0.3s ease;
    margin-right: -20px;
  }
}
.get-touch {
  border-left: 2px solid $white-color;
  @include media-breakpoint-down(lg) {
    margin-top: auto;
    margin-bottom: 10px;
  }
  h5 {
    color: $white-color;
    text-transform: uppercase;
    font-size: 25.5px;
    font-weight: normal;
    margin-bottom: 0;
  }
}

.fixed-top {
  @include transition(0.4s);
  @include media-breakpoint-down(lg) {
    background-color: $black-color;
    mix-blend-mode: normal;
  }
  &.menuopen {
    background: $black-color;
    mix-blend-mode: normal;
    @include transition(0.4s);
  }
}

header {
  @include media-breakpoint-down(md){
    position: relative;z-index: 9999;
  }
  .container {
    max-width: 100%;
    padding: 0px 32px;
    @include media-breakpoint-up(xl) {
      margin-top: 5px;
    }
    @include media-breakpoint-down(md) {
      padding: 0px 0px;
    }
    .navbar-collapse {
      @include transition(0.4s);
      justify-content: end;
      @include media-breakpoint-down(md) {
        background: $white-color;
        padding: 0px 10px;
        position: fixed;
        top: 0;
        right: 0;
        left: auto;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 99;
        padding-top:90px!important;
        display: block!important;
        height: 100vh!important;
        transform: translateX(100%);
        @include transition (.3s);
        width: calc(100% - 90px);
        &.show {
          transform: translateX(0);
        }
      }
    }
    .navbar {     
      @include media-breakpoint-up(md) {
        justify-content: end;
        padding-bottom: 0px;
      }
      .navbar-nav {
        @include media-breakpoint-down(md) {
          position: relative;
          overflow-y: scroll;
          max-height: calc(100vh - 100px);
          overflow-x: hidden;
        }
        > .menu-item {
          @include media-breakpoint-down(md) {
            &:not(:last-child){
              border-bottom: 1px solid #ddd;
            }            
          }
          @include media-breakpoint-up(md) {
            margin-left: 18px;
          }
          > .nav-link{
            text-transform: uppercase;
            font-size: 16px;
            color: $black-color;
            &:hover{
              @include media-breakpoint-up(md) {
              background-color:$black-color;
              color: $white-color;
              }
            }
          }
          /****Megamenu****/
          &.megamenu {
            @include media-breakpoint-up(md) {
              position: static;
            }
            > li {
              > ul {
                > li {
                  margin-bottom: 12px;
                }
              }
            }
            &:hover {
              @include media-breakpoint-up(lg) {
                > .dropdown-menu {
                  display: flex;
                  justify-items: start;
                }
              }
            }
            /****secound level****/
            > .dropdown-menu {
              &.menuclose{
                display: none;
              }
              > li {
                padding: 12px 6px;
                margin-bottom: 0px;
                @include media-breakpoint-up(lg) {
                  width: 16.66666%;
                  padding: 8px 6px;
                }
                > a {
                  //color: $red;
                  text-transform: uppercase;
                  font-size: 14px;
                  line-height: 1.2;
                  border-bottom: 1px solid #5b5b5b;                 
                  display: block;
                  margin-bottom: 0px;
                  padding:0px 0px 8px 0px;
                  @include media-breakpoint-up(lg) {
                    min-height: 42px;
                  }                  
                }
                .dropdown-menu {
                  position: static;
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }         
          /****First level****/
          > .dropdown-menu {
            border: none;
            box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.5);
            display: flex;
            font-family: $font-body;
            margin: -1px 0px 0px;
            padding: 0px 6px;
            width: 100%;
            pointer-events: none;
            -webkit-transform: translate(0, 10px);
            -moz-transform: translate(0, 10px);
            -o-transform: translate(0, 10px);
            transform: translate(0, 10px);
            transition: opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in;
            transition-delay: 0.35s;
            visibility: hidden;
            @include opacity(0);
            @include border-radius(0px);
            //background-color: #232323 !important;  
            @include media-breakpoint-down(md) {
              box-shadow: none;
              display: none;
              position: relative;
              -webkit-transform: translate(0, 0px);
              -moz-transform: translate(0, 0px);
              -o-transform: translate(0, 0px);
              transform: translate(0, 0px);
              visibility: visible;
              @include opacity(1);
              @include trans-anim-none;
              &.show {
                display: block;
                pointer-events: auto;
              }
            }
            li{
              margin-bottom: 12px;
              &:last-child{
                margin-bottom: 0px;
              }
              /*****Third Level****/
              > .dropdown-menu {
                background-color: transparent;
                box-shadow: none;
                border: none;
                .nav-link{
                  font-size: 16px;
                  line-height: 1;
                  letter-spacing: 1px;        
                  padding: 0px;                  
                }
              }
            }
          }  
                  
          &:hover {
            @include media-breakpoint-up(lg) {
              > .dropdown-menu {
                display: block;
                pointer-events: auto;
                -webkit-transform: translate(0, 0px);
                -moz-transform: translate(0, 0px);
                -o-transform: translate(0, 0px);
                transform: translate(0, 0px);
                visibility: visible;
                @include opacity(1);                
              }
            }            
          }
          /****Simple menu****/
          &:not(.megamenu){
            > ul {
              padding: 8px 5px;
              flex-direction: column;
              li{
                margin-bottom: 0px;
                border-bottom: 1px solid #000;
                padding-bottom: 6px;
                &:last-child{
                  padding-bottom: 0px;
                  border-bottom: 0px;
                }
                > a {
                  font-size: 16px;
                  line-height: 1;
                  letter-spacing: 1px;
                }
              }              
            }
          }
          .caret{           
            display: none;
            cursor: pointer;
            position: absolute;
            top: 1px;
            right: 0px;
            height: 35px;
            width: 43px;
            z-index: 1;
            background-size: 16px;
            transition: all 0.3s ease;            
            pointer-events: none;
            &::after{
              content: '';
              background: url(../../img/down-arrow.svg) no-repeat center;
              height: 35px;
              width: 43px;
              background-size: 16px;
              display: block;
              @include media-breakpoint-down(md) {
                filter: invert(1);
              }
            }
            @include media-breakpoint-down(md) {
              display: block;
            }
          }
          &.show{
            .caret::after{
              transform: rotate(180deg);
            }
          }          
        }           
      }
    }
  }
}

.carousel-club {
  width: 100%;
  overflow: hidden;
  direction: ltr;
  background: #3d94d7;
  margin-bottom: 10px;
  .carousel-club-inner {
    width: 100%;
    display: flex;
    color: #fff;
    > * {
      white-space: nowrap;
      padding: 0 0rem;
      direction: ltr;
    }
    .carousel-club-slide {
      color: #fff;
      padding: 3px 7px;
      display: inline-block;
      border-right: 1px solid #ddd;
      cursor: pointer;
    }
  }
}

.marquee-container {
  background-color: #060606;
  overflow: hidden;
}
.marquee {
  display: flex;
  flex-wrap: nowrap;
  background-color: #060606;
  font-size: 18px;
  color: #fff;
  font-family: "Bebas Neue", cursive;
  padding: 7px 0px;
  width: 100%;
  position: relative;
  > * {
    white-space: nowrap;
  }
  @include media-breakpoint-down(md) {
    padding: 5px 0 0px;
  }
  a {
    padding: 0px 7px;
    color: #fff;
    font-size: 20px;
    white-space: nowrap;
    min-width: max-content;
    &:after {
      content: "";
      height: 7px;
      width: 7px;
      border-radius: 100px;
      background: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
    img {
      width: 20px;
      display: none;
      vertical-align: text-bottom;
    }
    span {
      color: #d1ff3b;
    }
  }
  .slick-slide {
    float: right;
  }
  .slick-track {
    display: flex;
    flex-wrap: nowrap;
  }
}

@keyframes sliderdown {
  0% {
    top: -50px;
  }
  50% {
    top: -1px;
  }
  100% {
    top: 0px;
  }
}
a.nav-link[href="#contactSec"] {
  @include media-breakpoint-down(md){
    border-radius: 35px;
    border: 1px  solid #000;
    padding: 9px 10px;
    text-align: center;
  }  
}