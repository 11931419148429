.hibernate-left-shadow, .hibernate-right-shadow{
    position: absolute;        
    z-index: -1;       
    top:0px;
    width: 10%;
    height: auto;
    @include media-breakpoint-down(sm){
        width: 18%;
    }
}
.hibernate-left-shadow{
    left: 0;
}
.hibernate-right-shadow{
    right: 0;
}
.inr-title-flwr{
position: relative;
display: table;
margin-top: 43px;
    .title-flwr{
        position: absolute;
        bottom: 100%;
        width: 10%;
    }    
}