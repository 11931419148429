
.typescript-plus{
    position: absolute;
    top: 0;
    right: 30%;
    z-index: -1;
    @include media-breakpoint-down(sm){
        right: 0;
    }
}
.typescript-round{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    @include media-breakpoint-down(sm){
        bottom: -106px;
    }
}