body.tool-page {
  background-color: #fff;
  color: $black-color;
  .first-screen {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: calc(100vh - 57px);
    height: calc(var(--vh, 1vh) * 100 - 57px);
    .tool-sec1 {
      padding-bottom: 0px;
    }
    .tool-sec2 {
      padding-top: 0px;
    }
  }
  .feedbak-first-screen {
    @media screen and (min-height: 750px) {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      height: calc(100vh - 57px);
      height: calc(var(--vh, 1vh) * 100 - 57px);
      .feedback-sec3 {
        margin-top: 0px;
      }
    }
  }
  .inner-page {
    padding: 70px 0px 0px;
    @include media-breakpoint-down(md) {
      padding: 57px 0px 0px;
    }
  }
  cursor: default;
  .cursor,
  .cursor-follower {
    display: none;
  }
  .modal-backdrop {
    background: none;
  }
  .backgroundtool {
    background-color: $black-color;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: invert(1);
  }  
  .form-group .form-control {
    border: 1px solid $black-color;
  }
  &:not(.menuopen) .fixed-top {
    @include media-breakpoint-down(md) {
      background: $white-color;
      border-bottom: 1px solid #eaeaeadd;
    }
  }
  &.menuopen {
    .navbar-toggler,
    .navbar-brand {
      filter: none;
    }
  }
  
  .btn {
    font-size: 24px;
    padding: 12px 24px;
    @include border-radius(7px);
    vertical-align: middle;
    border: none;
    color: $white-color;
    //  box-shadow:0px 3px 14px 0px #8c8c8c;
    &.btn-secondary {
      @include linear-gradient(to right,#fd7f80 0%,#fe6a76 12%,#fc5d6f 16%,#fe5d6f 17%,#fe586c 18%,#fd546b 20%,#ff2852 36%,#ff2854 37%,#fe2250 39%,#ff1248 47%,#ff0f49 51%,#ff0f49 100%);
    }
    &:hover {
      color: $white-color;
    }
    img {
      max-width: 28px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-bottom;
    }
    &.btn-info {
      @media only screen and (max-width: 430px) {
        margin-top: 26px;
      }
    }
  }
  .sound-buttons {
    filter: invert(1);
  }
  .global-noise{
    opacity: 0.05;
  }
}
.copytext {
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  background: #f1f1f1;
  padding: 5px 7px;
  border-radius: 4px;
  display: inline-block;
  img {
    width: 29px;
    @include media-breakpoint-down(md) {
      width: 27px;
    }
  }
}
.sticky-btn {
  position: fixed;
  right: 0px;
  top: 50%;
  border-radius: 10px 0px 0px 10px !important;
  font-size: 22px !important;
  font-weight: bold;
  padding: 11px 23px !important;
  line-height: 1.1;
  min-width: auto;
  z-index: 1;
  transform: translate(0, -50%);
  @include media-breakpoint-down(md) {
    font-size: 13px !important;
    bottom: 75px;
    padding: 8px 16px !important;
    top: auto;
  }
}
.inner-page.tool {
  .tool-left-shadow,
  .tool-right-shadow {
    position: absolute;
    z-index: -1;
    top: -30px;
    width: 8%;
    height: auto;
    @include media-breakpoint-down(sm) {
      width: 18%;
    }
  }
  .tool-left-shadow {
    left: 0;
  }
  .tool-right-shadow {
    right: 0;
    top: 50%;
  }
  .tool-dot1 {
    right: -47px;
    top: 70px;
    position: absolute;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .tool-sec-tool {
    margin: 56px auto;
    @include media-breakpoint-down(md) {
      margin: 0px auto 60px auto;
    }
    .bdr-right {
      border-right: 4px solid #ddd;
    }
    .tool-icon {
      height: 84px;
      @include media-breakpoint-down(md) {
        height: 59px;
      }
    }
    strong {
      line-height: 1.2;
      display: block;
      @include fluid-type($min_width, $max_width, 17px, 24px);
    }
  }
  h1 {
    position: relative;
    @include fluid-type($min_width, $max_width, 32px, 80px);
    display: table;
    margin: 0px auto 60px;
    @include media-breakpoint-down(md) {
      margin: 0px auto 0px;
    }
    strong {
      font-weight: 800;
    }
    .tool-title-hand {
      position: absolute;
      right: 97%;
      width: 38px;
      bottom: -14px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  h2.curvebg {
    font-weight: 400;
    position: relative;
    display: table;
    color: $white-color;
    margin: 0px auto 34px;
    &:after {
      content: "";
      height: 130%;
      width: 110%;
      background-color: $black-color;
      position: absolute;
      top: -15%;
      left: -6%;
      z-index: -1;
      @include transform(rotate(-2deg));
    }
    .tool-title-hand {
      position: absolute;
      left: 100%;
      max-width: 66px;
      width: 38px;
      top: -50px;
      @include transform(rotate(-172deg));
    }
  }
}
.tool-plus {
  position: absolute;
  right: 90px;
  bottom: 0;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.tool-star {
  margin-top: -10%;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.tool-dot2 {
  margin-top: -10%;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.tool-box2 {
  position: absolute;
  top: 0;
  left: 100%;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.tool-box3 {
  position: absolute;
  bottom: 0;
  left: 100%;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.tool-form {
  @include media-breakpoint-up(lg) {
    min-width: 510px;
  }
  .form-control {
    height: 40px;
    @include border-radius(7px);
    border-color: #b9b9b9;
    color: $black-color;
    @include placeholder {
      color: $black-color;
    }
  }
  .form-row {
    margin-bottom: 15px;
  }
}
.tool-modal {
  .pop-title {
    display: block;
    margin: 0px auto 0;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      margin: 0px auto 0;
    }
  }
  .close {
    background: #f44336;
    @include opacity(1);
    color: #fff;
    border-radius: 5px;
    padding: 6px 9px;
    font-size: 18px;
    margin: 0px;
    cursor: pointer;
    right: 0;
    top: 0;
  }
  .modal-content {
    box-shadow: 0px 51px 98px 0px rgba(125, 104, 69, 0.82);
    border: none;
    .modal-header {
      border: none;
    }
    .close {
      position: absolute;
      top: -10px;
      right: -5px;
    }    
    .modaltool-data {
      background: #f8f8f8;
      @include border-radius(10px);
      padding: 30px 15px 0;
      margin-bottom: 20px;
      .modaltool-col {
        margin-bottom: 30px;
        h5 {
          font-weight: 400;
          font-size: 32px;
          margin-bottom: 8px;
        }
        p {
          font-family: $font-body;
          font-size: 20px;
          margin-bottom: 0px;
          line-height: 1.2;
        }
      }
    }
    .no-trackdata {
      font-weight: bold;
      text-align: center;
      @include media-breakpoint-up(md) {
        margin: -43px auto 40px;
      }
    }
    .download-track {
      background: #f1f1f1;
      padding: 5px 7px;
      display: inline-block;
      border-radius: 4px;
      cursor: pointer;
      vertical-align: middle;
      img {
        width: 29px;
        @include media-breakpoint-down(md) {
          width: 27px;
        }
      }
    }
  }
}
#show-data {
  min-width: 100%;
  overflow: hidden;
  #trackContainer {
    margin-top: -55px;
    margin-bottom: -144px;
    @include media-breakpoint-down(md) {
      margin-bottom: -53px;
    }
  }
}
.feed-link {
  background-color: $white-color;
  border: 3px solid #486fee;
  @include border-radius(4px);
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 20px;
  margin: 0px 0px 54px;
  word-break: break-word;
}
.flag-list.list-unstyled {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
  margin-top: 70px;
  li {
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    a {
      overflow: hidden;
      display: block;
      margin: 0px auto 20px;
      cursor: pointer;
      img {
        border: 1px solid #efefef;
      }
      &:hover {
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
        img {
          @include transition(0.5s);
          @include transform(scale(1.04));
        }
      }
      @include media-breakpoint-up(xl) {
        max-width: 80%;
      }
    }
    strong {
      font-size: 18px;
      line-height: 1.2;
      display: block;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }
}
.toast {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  background: $black-color;
  color: #fff;
  padding: 0px 14px;
  backdrop-filter: 0px;
  overflow: visible;
  &:before {
    content: "▼";
    left: 50%;
    position: absolute;
    top: 100%;
    color: $black-color;
    line-height: 1;
    margin: -2px 0px 0px -4px;
  }
}
/******Comment Section*****/
/* -------------------------------------- */
/*	12. Comments
/* -------------------------------------- */
/* COMMENT HEADER */
.comment-reply-title {
	margin: 30px 0 1.5rem 0;
	text-align: center;
	font-weight: bold;
	small {
		display: block;
		font-size: 1.6rem;
		font-weight: 600;
		letter-spacing: -0.0277em;
		margin: 0.5rem 0 0 0;
		white-space: nowrap;
		a {
			text-decoration: none;
			&:focus {
				text-decoration: underline;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.comment-notes {
	display: none;
}
div.comment {
	&:first-of-type {
		margin-top: 1.5rem;
		padding-top: 0;
	}
}
.comments {
	.comments-header {
		+ {
			div {
				margin-top: 0;
				padding-top: 0;
			}
		}
	}
	.comment-respond {
		.comment-reply-title {
			text-align: left;
		}
		.comment-notes {
			text-align: left;
		}
		.logged-in-as {
			text-align: left;
		}
	}
}
.comment-body {
	position: relative;
}
.comment {
	.comment {
		padding-left: 5%;
	}
	margin-bottom: 30px;
}
.hide-avatars {
	.comment-meta {
		min-height: 0;
		padding-left: 0;
	}
}
.comment-meta {
	a {
		color: inherit;
	}
	.avatar {
		height: 4rem;
		position: absolute;
		left: 0;
		top: 9px;
		width: 4rem;
	}
	padding-left: 0;
}
.comment-author {
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
	}
	.url {
		text-decoration: underline;
	}
}
.comment-metadata {
	color: #6d6d6d;
	font-size: 14px;
	a {
		text-decoration: none;
		&:focus {
			text-decoration: underline;
		}
		&:hover {
			text-decoration: underline;
		}
	}
}
.comment-footer-meta {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	font-size: 1.2rem;
	font-weight: 600;
	justify-content: flex-start;
	letter-spacing: 0.030833333em;
	line-height: 1;
	>* {
		margin: 0 1rem 1rem 0;
	}
	a {
		text-decoration: none;
		&:focus {
			text-decoration: underline;
		}
		&:hover {
			text-decoration: underline;
		}
	}
}
.comment-reply-link {
	@include linear-gradient(to right,#fd7f80 0%,#fe6a76 12%,#fc5d6f 16%,#fe5d6f 17%,#fe586c 18%,#fd546b 20%,#ff2852 36%,#ff2854 37%,#fe2250 39%,#ff1248 47%,#ff0f49 51%,#ff0f49 100%); color: #fff;
	display: block;
	font-size: 15px;
	padding: 8px 10px;
}
.bypostauthor {
	.comment-footer-meta {
		.by-post-author {
			display: block;
			text-transform: none;
			font-weight: 500;
		}
	}
}
.pingback {
	.comment-meta {
		padding-left: 0;
	}
}
.trackback {
	.comment-meta {
		padding-left: 0;
	}
}
.comments-pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 6rem;
	text-align: center;
	.page-numbers {
		display: none;
		text-decoration: none;
		&:focus {
			text-decoration: underline;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	.prev {
		display: block;
		left: 0;
	}
	.next {
		display: block;
		right: 0;
		text-align: right;
	}
}
.comments-pagination.only-next {
	justify-content: flex-end;
}
.comment-respond {
	&::after {
		clear: both;
		content: "";
		display: block;
	}
	.comment-notes {
		a {
			color: inherit;
			text-decoration: none;
			&:focus {
				text-decoration: underline;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.logged-in-as {
		a {
			color: inherit;
			text-decoration: none;
			&:focus {
				text-decoration: underline;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
	p {
		line-height: 1.1;
		margin-bottom: 2rem;
		margin-left: auto;
		margin-right: auto;
	}
	>p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	label {
		display: block;
	}
	input[type="checkbox"] {
		+ {
			label {
				font-size: 20px;
				line-height: 1;
				letter-spacing: 1px;
			}
		}
	}
	input[type="text"] {
		margin-bottom: 0;
	}
	input[type="email"] {
		margin-bottom: 0;
	}
	textarea {
		margin-bottom: 0;
		height: 150px;
	}
	#submit {
		display: block;
	}
	.comments-closed {
		text-align: center;
	}
}
.comment-form-cookies-consent {
	align-items: baseline;
	display: flex;
}
.comments-inner {
	margin-left: 6rem;
}
.reply-form-title {
	text-align: left;
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 10px;
}
p.comment-form-email {
	float: left;
	width: calc(50% - 15px);
	margin-left: 30px;
}
p.comment-form-cookies-consent {
	clear: both;
}
.form-submit {
  input[type="submit"] {
    @include linear-gradient(to right,#fd7f80 0%,#fe6a76 12%,#fc5d6f 16%,#fe5d6f 17%,#fe586c 18%,#fd546b 20%,#ff2852 36%,#ff2854 37%,#fe2250 39%,#ff1248 47%,#ff0f49 51%,#ff0f49 100%);
    color: #fff;
    padding: 8px 15px;
    border: none;
  }
}
p.comment-form-author {
  float: left;
  width: calc(50% - 15px);
}
.comment-meta .avatar {
  left: -6rem;
}
.entry-content {
  font-size: 20px;
  margin-top: 9px;
  p {
    margin-bottom: 15px;
  }
}
.comment-author {
  text-transform: capitalize;
}
/* Screen Reader Text ------------------------ */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;
}
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  right: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
.comment-respond {
  label {
    font-size: 20px;
  }
  input {
    &[type="text"],
    &[type="email"] {
      @extend .form-control;
      border-radius: 0px;
      color: #070707;
      border: 1px solid $black-color;
      height: 34px;
      &:focus {
        color: $black-color;
      }
    }
  }
  textarea {
    @extend .form-control;
    border-radius: 0px;
    color: #070707;
    border: 1px solid $black-color;
    &:focus {
      color: $black-color;
    }
  }
  input[type="checkbox"] + label {
    display: inline;
    font-weight: 400;
    margin-left: 0.5rem;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    top: 6px;
    display: inline-block;
    margin: 0;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    background: #fff;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    border-color: $black-color;
    box-shadow: none;
    cursor: pointer;
  }
  input[type="checkbox"]:checked::before {
    /* Use the "Yes" SVG Dashicon */
    content: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Cpath%20d%3D%27M14.83%204.89l1.34.94-5.81%208.38H9.02L5.78%209.67l1.34-1.25%202.57%202.4z%27%20fill%3D%27%23000000%27%2F%3E%3C%2Fsvg%3E");
    position: absolute;
    display: inline-block;
    margin: -0.1875rem 0 0 -0.25rem;
    height: 1.75rem;
    width: 1.75rem;
  }
}
.track-error {
  @extend .invalid-feedback;
  display: none;
}